import { apiSlice } from './apiSlice';

export const ContactFormApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
   
    addContactFormData: builder.mutation({
      query: (Data) => ({
        url: `/contact_form`,
        method: 'POST',
        body: Data,
    }),
    invalidatesTags: [{ type: 'ContactFormData' }],
    }),
  }),
});


export const { useAddContactFormDataMutation } = ContactFormApi;
