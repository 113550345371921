

import Footer from '../../src/components/footer'
import React, { useEffect ,useState,useRef} from 'react';
import Header from '../components/header';
import HomeAbout from '../../src/assets/about.png';
import Philosophy from '../../src/assets/philosophy.png';
import About1 from '../../src/assets/about.jpg';
import InvestmentProcess from '../components/InvestementProcess';
import Unlock1 from '../../src/assets/unlock1.png';
import Unlock2 from '../../src/assets/unlock2.png';
import { Link } from 'react-router-dom';
import Index from '../components/index';
import OurServices from '../components/Our_Services';
import { useNavigate } from 'react-router-dom';
import MiddleEast from '../assets/middleeast.png';
import Asia from '../assets/asia.png'; 
import Africa from '../assets/africa.png'; 
import Europe from '../assets/europe.png';
import NorthAmerica from '../assets/northamerica.png'; 
import SouthAmerica from '../assets/southamerica.png';
import Oceania from '../assets/oceania.png';



function About() {
    const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);

const [isHovered, setIsHovered] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 768);
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      const isLargeScreen = window.innerWidth >= 1024;
      const regionImages = {
        Asia: Asia,
        'North America': NorthAmerica,
        'Middle East': MiddleEast,
        'South America': SouthAmerica,
        Europe: Europe,
        Africa: Africa,
        'Oceania': Oceania,
      };
      const [selectedRegion, setSelectedRegion] = useState('Middle East');
      const [hoveredRegion, setHoveredRegion] = useState(null);
  

        const [isUnlock1, setIsUnlock1] = useState(true); 
  useEffect(() => {
    const interval = setInterval(() => {
      setIsUnlock1((prev) => !prev); 
    }, 3000); 

    return () => clearInterval(interval); 
  }, []);

  useEffect(() => {
    // Update the screen size on mount and window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust for small screens
    };
    handleResize(); // Check screen size on component mount
    window.addEventListener("resize", handleResize); // Listen for screen resize
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  return (
    <>

<div className="page-wrapper">


    <Header/>
    <div className="image-container" style={{ position: 'relative', width: '100%', maxHeight: '400px' }}>
  <img 
    src={HomeAbout} 
    alt="Background" 
    style={{ 
      width: '100%', 
      maxHeight: '400px', 
      height: 'auto', 
      objectFit: 'cover' 
    }} 
  />
  <div 
    className="overlay" 
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.6)', 
      zIndex: 1
    }}
  />
  <div 
    className="text" 
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      fontSize: '36px',
      fontWeight: 'bold',
      zIndex: 2
    }}
  >
    About Us
  </div>
</div>


    <div style={{ maxWidth: "1200px", marginTop: "40px" ,marginBottom: "30px" }} className="container">
          <div className="row">
            {/* Left Image Section */}
            <div className="col-sm-6" style={{ position: "relative" }}>
              <img
                src={About1}
                alt="Team"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "5px",
                  objectFit: "cover",
                }}
              />
              {/* Overlay Testimonial */}
              <div
                style={{
                  position: "absolute",
                  bottom: "20px",
                  left: "20px",
                  background: "rgba(0, 0, 0, 0.7)",
                  color: "#fff",
                  padding: "15px",
                  borderRadius: "5px",
                  maxWidth: "90%",
                }}
              >
                <p style={{ margin: "0", fontSize: "14px" }}>
                Since 2024, Beta Investment Group has been dedicated to delivering tailored financial solutions. Despite challenges, we’ve adapted to meet client needs and stay ahead of market trends.
                </p>
                <p style={{ margin: "0", marginTop: "10px", fontWeight: "bold" }}>
                Raoul Aoun – CEO
                </p>
              </div>
            </div>

            {/* Right Content Section */}
            <div className="col-sm-6">
              <h4
                style={{
                  color: "#3a9d6a",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                What We Do
              </h4>
              <h2
                style={{
                  fontWeight: "bold",
                  fontSize: "22px"
                }}
              >
               Welcome to a new era of smart, informed investing!
              </h2>
              <p style={{ marginBottom: "20px", fontSize: "14px", lineHeight: "1.6" }}>
              At Beta, we provide personalized investment strategies and innovative solutions to grow and protect your wealth, while enhancing your brand’s presence for long-term success.
              </p>
              <div className="row">
  <div className="col-xs-6">
    <h5>
      <span
        style={{
          color: "blue",
          marginRight: "5px",
          display: "inline-block",
        }}
      >
        <i className="fa fa-briefcase text-success"></i>
      </span>
      Wealth Management
    </h5>
    <p style={{ fontSize: "13px" }}>
      Navigate the complexities of wealth management with our personalized strategies designed for sustainable growth.
    </p>
  </div>
  <div className="col-xs-6">
    <h5>
      <span
        style={{
          color: "green",
          marginRight: "5px",
          display: "inline-block",
        }}
      >
        <i className="fa fa-shield-alt text-success"></i>
      </span>
      Protecting Assets
    </h5>
    <p style={{ fontSize: "13px" }}>
      Safeguard your wealth with innovative solutions tailored to provide security and peace of mind.
    </p>
  </div>
  <div className="col-xs-6">
    <h5>
      <span
        style={{
          color: "green",
          marginRight: "5px",
          display: "inline-block",
        }}
      >
        <i className="fa fa-chart-pie text-success"></i>
      </span>
      Strategic Growth
    </h5>
    <p style={{ fontSize: "13px" }}>
      Unlock opportunities for financial success through data-driven, strategic investment planning.
    </p>
  </div>
  <div className="col-xs-6">
    <h5>
      <span
        style={{
          color: "green",
          marginRight: "5px",
          display: "inline-block",
        }}
      >
        <i className="fa fa-globe text-success"></i>
      </span>
      Exploring Opportunities
    </h5>
    <p style={{ fontSize: "13px" }}>
      Discover innovative and global investment opportunities that align with your financial aspirations.
    </p>
  </div>
</div>

              <div >
                <button
                  style={{
                    backgroundColor: "#3a9d6a",
                    color: "#fff",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    marginRight: "15px",
                  }}
                >
                  <Link to="/contact" className='text-white'>Contact Us</Link>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div style={{ maxWidth: "1200px", marginTop: "40px", marginBottom: "30px" }} className="container">
      <div className="row">
      <div className="col-sm-6" style={{ height: "100%" }}>
          <h4
            style={{
              color: "#3a9d6a",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            KNOW MORE ABOUT US
          </h4>
          <div className="row" style={{ height: "100%" }}>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h5>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-book text-success"></i>
                </span>
                Our Story
              </h5>
              <p style={{ fontSize: "17px" }}>
                We believe in a disciplined, research-driven approach to investing. Our goal is to create diversified portfolios that minimize risk while maximizing returns. We identify growth opportunities across multiple sectors and regions through extensive market research to ensure our clients benefit from the best possible returns.
              </p>
            </div>
            <div className="col-xs-6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <h5>
                <span
                  style={{
                    color: "red",
                    marginRight: "5px",
                    display: "inline-block",
                  }}
                >
                  <i className="fa fa-users text-success"></i>
                </span>
                Our Team
              </h5>
              <p style={{ fontSize: "17px" }}>
                At the heart of Beta is a diverse team of seasoned financial experts, analysts, and portfolio managers. Each member brings a wealth of experience from top financial institutions and a shared commitment to helping clients achieve their financial goals.
              </p>
            </div>
            
          </div>
        </div>
        {/* Left Image Section */}
        <div className="col-sm-6" style={{ position: "relative", height: "100%" }}>
          <img
            src={Philosophy}
            alt="Team"
            style={{
              width: "100%",
              height: "50vh", // Ensure the image takes the full height of the container
              borderRadius: "5px",
              objectFit: "cover", // Ensures the image covers the area without distorting
            }}
          />
          {/* Overlay Testimonial (if needed) */}
        </div>

    
      </div>
    </div>


    <OurServices/>

<br/>
<br/>

<InvestmentProcess/>
<br/>


<div
      className="container-fluid"
      style={{
        backgroundColor: '#F4F2E3',
        padding: '40px 20px',
      }}
    >
      <div className="row">
        <div className="col-xs-12 text-left" >
          <p
            style={{
              color: '#3a9d6a',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              marginBottom: '5px',
              fontSize: isLargeScreen ? '25px' : '15px',
            }}
          >
            Your Financial Partner, Across Borders
          </p>
          {/* <h2
            style={{
              fontWeight: 'bold',
              fontSize: isLargeScreen ? '36px' : '25px',
              marginBottom: '30px',
              color: "#13264C",
            }}
          >
            World Wide Locations
          </h2> */}
        </div>
      </div>
      {!isLargeScreen && (
  <div
    className={`col-xs-12 col-md-4 ${isLargeScreen ? 'text-start' : 'text-center'}`}
    style={{
      marginTop: '20px', // Reduced margin for closer placement to the image
    }}
  >
    <ul
      className="list-unstyled"
      style={{
        fontSize: '20px',
        lineHeight: '2',
        display: isLargeScreen ? 'inline-block' : 'flex',
        flexWrap: 'wrap', // Allows items to wrap on small screens
        justifyContent: 'space-between', // Spacing between items
        textAlign: 'left', // Aligns text if needed
      }}
    >
      {Object.keys(regionImages).map((region, index) => (
        <li
          key={region}
          style={{
            marginBottom: '10px',
            width: isLargeScreen ? '100%' : '48%',
          }}
        >
           <span
            onClick={() => setSelectedRegion(region)}
            onMouseEnter={() => {
              setHoveredRegion(region);
              setSelectedRegion(region);
            }}
            onMouseLeave={() => setHoveredRegion(null)}
            style={{
              cursor: 'pointer',
              color:
                selectedRegion === region || hoveredRegion === region
                  ? '#3a9d6a'
                  : '#000',
              fontWeight:
                selectedRegion === region || hoveredRegion === region
                  ? 'bold'
                  : 'normal',
              fontSize:
                selectedRegion === region || hoveredRegion === region
                  ? isLargeScreen
                    ? '30px'
                    : '18px'
                  : isLargeScreen
                  ? '20px'
                  : '14px',
              transition: 'all 0.3s ease',
            }}
            className={`animate__animated ${
              selectedRegion === region || hoveredRegion === region
                ? 'animate__fadeInUp'
                : ''
            }`}
          >
            {region} <span
              style={{
                display: 'inline-block',
                width: '8px',
                height: '8px',
                backgroundColor:
                  selectedRegion === region || hoveredRegion === region
                    ? 'green'
                    : '#000',
                borderRadius: '50%',
                transition: 'background-color 0.3s ease',
              }}
            />
          </span>
        </li>
      ))}
    </ul>
  </div>
)}

      <div className="row">
  {/* Image Section */}
  <div
    className="col-xs-12 col-md-8 text-center"
    style={{
      position: 'relative',
      margin: '0 auto',
      textAlign: 'center',
      marginBottom: '10px', // Reduced margin to make the space smaller
      display: 'flex',
      justifyContent: 'center', // Centers the image horizontally
      alignItems: 'center',    // Centers the image vertically if needed
    }}
  >
    {selectedRegion && (
      <div
        style={{
          position: 'relative', // Keeps it centered
        }}
        className="animate__animated animate__fadeInUp"
      >
        <img
          src={regionImages[selectedRegion]} // Dynamically updates the image
          alt={selectedRegion}
          style={{
            width: '1000px',
            borderRadius: '4px',
            transition: 'filter 0.3s ease',
          }}
        />
      </div>
    )}
  </div>

  {/* Region List Section */}
  {isLargeScreen && (
  <div
    className={`col-xs-12 col-md-4 ${isLargeScreen ? 'text-start' : 'text-center'}`}
    style={{
      marginTop: '20px', // Reduced margin for closer placement to the image
    }}
  >
    <ul
      className="list-unstyled"
      style={{
        fontSize: '20px',
        lineHeight: '2',
        display: isLargeScreen ? 'inline-block' : 'flex',
        flexWrap: 'wrap', // Allows items to wrap on small screens
        justifyContent: 'space-between', // Spacing between items
        textAlign: 'left', // Aligns text if needed
      }}
    >
      {Object.keys(regionImages).map((region, index) => (
        <li
          key={region}
          style={{
            marginBottom: '10px',
            width: isLargeScreen ? '100%' : '48%',
          }}
        >
          <span
            onClick={() => setSelectedRegion(region)}
            onMouseEnter={() => {
              setHoveredRegion(region);
              setSelectedRegion(region);
            }}
            onMouseLeave={() => setHoveredRegion(null)}
            style={{
              cursor: 'pointer',
              color:
                selectedRegion === region || hoveredRegion === region
                  ? '#3a9d6a'
                  : '#000',
              fontWeight:
                selectedRegion === region || hoveredRegion === region
                  ? 'bold'
                  : 'normal',
              fontSize:
                selectedRegion === region || hoveredRegion === region
                  ? isLargeScreen
                    ? '30px'
                    : '22px'
                  : isLargeScreen
                  ? '20px'
                  : '16px',
              transition: 'all 0.3s ease',
            }}
            className={`animate__animated ${
              selectedRegion === region || hoveredRegion === region
                ? 'animate__fadeInUp'
                : ''
            }`}
          >
            {region}
          </span>
        </li>
      ))}
    </ul>
  </div>
)}

</div>

    </div>

<div
  className="container-fluid"
  style={{
    position: "relative",
    width: "100vw",
    margin: "0",
    padding: "0",
  }}
>
  <div
    style={{
      backgroundImage: `url(${isUnlock1 ? Unlock1 : Unlock2})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "70vh",
      position: "relative",
    }}
  >
    {/* Overlay */}
    <div
      className="overlay"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        zIndex: 1,
      }}
    />

    <div
      className="container text-white d-flex flex-column justify-content-center align-items-center"
      style={{
        position: "absolute",
        top: isSmallScreen ? "5%" : "25%",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 2,
        padding: "0 15px",
      }}
    >
      <div style={{ textAlign: "center", margin: "0 auto" }}>
  <h1 
    className="fw-bold" 
    style={{ 
      fontSize: isLargeScreen ? "3rem" : "1.5rem", 
      display: "inline-block", 
      margin: "0" 
    }}>
 Unlock Your Financial Success
  </h1>
  <p 
    style={{ 
      fontSize: isLargeScreen ? "1rem" : "0.8rem", 
      margin: "20px 0", 
      display: "inline-block", 
      width: "50%", 
      textAlign: "center"
    }} 
    className="text-center">
     With our expert-driven, personalized investment strategies that deliver consistent growth and security
  </p>
</div>
      
      <button
        className="btn btn-outline-light"
        style={{
          borderRadius: "25px",
          fontSize: "1rem",
          backgroundColor: isHovered ? "#3a9d6a" : "transparent",
          borderColor: isHovered ? "#3a9d6a" : "#fff",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Link
          to="/service"
          style={{
            color: isHovered ? "#fff" : "#fff",
            textDecoration: "none",
          }}
        >
          View More Details
        </Link>
      </button>
    </div>

    {/* Stats Section */}
    <div
      className="container-fluid d-flex justify-content-between align-items-center text-white flex-wrap"
      style={{
        position: "absolute",
        bottom: "10px", // Position at the bottom
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        zIndex: 3,
        width: "calc(100% - 20px)", // Prevent overflow
        boxSizing: "border-box",
      }}
    >
      {[
        { count: "5+", label: "Years Experience" },
        { count: "4", label: " Investment Solutions" },
        {
          count: (
            <>
              <i className="fa fa-star text-yellow-400"></i>
              <i className="fa fa-star text-yellow-400"></i>
              <i className="fa fa-star text-yellow-400"></i>
              <i className="fa fa-star text-gray-300"></i>
              <i className="fa fa-star text-gray-300"></i>
            </>
          ),
          label: "Investor Satisfied"
        },        
        { count: "200+", label: "Projects Completed" },
      ].map((item, index) => (
        <div
          key={index}
          className="text-center stats-item"
          style={{
            flex: isSmallScreen ? "1 1 50%" : "1 1 25%",
            padding: "0 15px",
            cursor: "pointer",
            borderLeft: index !== 0 ? "1px solid rgba(255, 255, 255, 0.3)" : "none",
            transition: "transform 0.3s ease",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.1)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "scale(1)";
          }}
        >
          <h2
            style={{
              color: "#3a9d6a",
              fontSize: "2rem",
              transition: "color 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = "#ffffff";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = "#3a9d6a";
            }}
          >
            {item.count}
          </h2>
          <p>{item.label}</p>
        </div>
      ))}
    </div>
  </div>
</div>


<Index/>

     
	
	
    <Footer/>
	
</div>

<div className="search-popup">
	<button className="close-search style-two"><span className="icofont-brand-nexus"></span></button>
	<button className="close-search"><span className="icofont-arrow-up"></span></button>
	<form method="post" action="blog.html">
		<div className="form-group">
			<input type="search" name="search-field" placeholder="Search Here" required=""/>
			<button type="submit"><i className="fa fa-search"></i></button>
		</div>
	</form>
</div>

<div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>


   </>
  );
}

export default About;
