import React, { useState ,useEffect} from "react";
import Services from '../../src/assets/services.png';
import Service1 from '../../src/assets/service1.png';
import Service2 from '../../src/assets/service2.png';
import Service3 from '../../src/assets/service3.png';
import Service4 from '../../src/assets/service4.png';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';

function OurServices() {

  
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    const contentData = [
        {
          title: "Wealth Management",
          text: "Tailored Investing to your financial goals and risk appetite, ensuring a personalized and strategic approach for sustainable growth.",
          image: Service1,
        },
        {
          title: "Investment Advisory",
          text: "Personalized Investment Solutions designed to meet the unique needs of each client, with a focus on strategic planning and optimal outcomes.",
          image: Service2,
        },
        {
          title: "Financial Planning",
          text: "Create a long-term wealth management plan encompassing your investment strategy, tax planning, retirement goals to secure your future.",
          image: Service3,
        },
        {
          title: "Corporate Financial Management",
          text: "Specialize in understanding the local market and economic challenges, offering investment options that align with Lebanon-specific financial landscape and objectives.",
          image: Service4,
        },
      ];

  return (
  
    <div
      className="bg-black text-white position-relative"
      style={{
        padding: "20px 0",
      }}
    >
      {/* Background Image */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${Services})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          zIndex: 1,
        }}
      ></div>

      {/* Overlay */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.82)", 
          zIndex: 2, 
        }}
      ></div>

      {/* Content */}
      <div className="container position-relative" style={{ zIndex: 3 }}>
  <div className="row">
    <div className="sec-title col-md-12 text-left mb-4">
      <h4
        className="text-uppercase text-success"
        style={{ fontWeight: "bold" }}
      >
        Our Solutions
      </h4>
      <h4
        className="text-uppercase text-white mb-2"
        style={{ fontWeight: "bold" }}
      >
        Comprehensive Investment Solutions for Lasting Financial Success
      </h4>
      
    </div>
    {isLargeScreen ? (
  contentData.map((item, idx) => (
    <div
      key={idx}
      className={`col-md-3 col-sm-6 col-xs-6 animate__animated animate__fadeInUp animate__delay-${idx + 2}s`}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRight: idx < contentData.length - 1 ? "1px solid white" : "none",
        paddingRight: "15px",
      }}
    >
      <div
        className="reputation-block"
        style={{
          borderRadius: "8px",
          overflow: "hidden",
          display: "flex",
          flexDirection: isLargeScreen
            ? [1, 3].includes(idx)
              ? "column-reverse"
              : "column"
            : "column", // This logic is the same for small and large screens for idx 1 and 3
          minHeight: "500px",
        }}
      >
        <img
          src={item.image}
          alt={item.title}
          className="img-responsive"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
        <div
          className="p-3"
          style={{
            textAlign: "center",
          }}
        >
          <h5
            className="text-white fw-bold mb-2 text-left"
            style={{ fontSize: "1.5rem", textAlign: "left" }}
          >
            {item.title}
          </h5>
          <p
            className="text-white"
            style={{ fontSize: "1rem", lineHeight: "1.5", textAlign: "left" }}
          >
            {item.text}
          </p>
          <Link
            to={
              idx === 0
                ? "/service/wealth"
                : idx === 1
                ? "/service/investment"
                : idx === 2
                ? "/service/financial"
                : "/service/corporate"
            }
            className="text-success text-uppercase"
            style={{ fontSize: "1rem", textDecoration: "none" }}
          >
            Know more &nbsp;{" "}
            <span className="glyphicon glyphicon-arrow-right"></span>
          </Link>
        </div>
      </div>
    </div>
  ))
) : (
  <Carousel  prevIcon={
    <span
      aria-hidden="true"
      className="carousel-control-prev-icon"
      style={{ display: 'none' }}
    />
  }
  nextIcon={
    <span
      aria-hidden="true"
      className="carousel-control-next-icon"
      style={{ display: 'none' }}
    />
  }>
  {contentData.map((item, idx) => (
    <Carousel.Item key={idx}>
      <div
        className={`col-md-3 col-sm-6 col-xs-6 `}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingRight: "15px",
        }}
      >
        <div
          className="reputation-block"
          style={{
            borderRadius: "8px",
            overflow: "hidden",
            display: "flex",
            flexDirection: isLargeScreen
              ? [1, 3].includes(idx)
                ? "column-reverse"
                : "column"
              : "column",
            minHeight: "500px",
          }}
        >
          <img
            src={item.image}
            alt={item.title}
            className="img-responsive"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
          <div
            className="p-3"
            style={{
              textAlign: "center",
            }}
          >
            <h5
              className="text-white fw-bold mb-2 text-left"
              style={{ fontSize: "1.5rem", textAlign: "left" }}
            >
              {item.title}
            </h5>
            <p
              className="text-white"
              style={{ fontSize: "1rem", lineHeight: "1.5", textAlign: "left" }}
            >
              {item.text}
            </p>
            <Link
              to={
                idx === 0
                  ? "/service/wealth"
                  : idx === 1
                  ? "/service/investment"
                  : idx === 2
                  ? "/service/financial"
                  : "/service/corporate"
              }
              className="text-success text-uppercase"
              style={{ fontSize: "1rem", textDecoration: "none" }}
            >
              Know more &nbsp;{" "}
              <span className="glyphicon glyphicon-arrow-right"></span>
            </Link>
          </div>
        </div>
      </div>
    </Carousel.Item>
  ))}
</Carousel>
)}


  </div>
</div>

    </div>
  );
}

export default OurServices;
