import { apiSlice } from './apiSlice';

export const FormApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFormData: builder.query({
    query: () => ({
      url: `/investments`,
      method: 'GET',
    }),
    providesTags: (result,error) => [{ type: 'FormData'}],
  }),
    addFormData: builder.mutation({
      query: (Data) => ({
        url: `/investments`,
        method: 'POST',
        body: Data,
    }),
    invalidatesTags: [{ type: 'FormData' }],
    }),
  }),
});


export const { useAddFormDataMutation ,useGetFormDataQuery} = FormApi;
