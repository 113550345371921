import React from "react";
import Services from "../../src/assets/services.png";
import About1 from "../../src/assets/about1.png";
import News from "../../src/assets/news-4.jpg";
import faq from "../assets/faq.svg";
import ContactForm from "../../src/assets/contactform.png";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const MenuModal = ({ onClose }) => {
    const navigate = useNavigate();
  return (
    <div
      style={{
        display: "block",
        background: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(10px)",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        padding: "5% 0",
      }}
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-lg"
        style={{
          width: "90%",
          maxWidth: "1000px",
          margin: "auto",
          backgroundColor: "#fff",
          borderRadius: "10px",
          boxShadow: "0 10px 25px rgba(0, 0, 0, 0.3)",
          position: "relative",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
            alignItems: "center",
          }}
        >
          <button
            className="btn btn-danger"
            style={{
              borderRadius: "50%",
              width: "42px",
              height: "42px",
              fontSize: "30px",
              textAlign: "center",
              lineHeight: "30px",
              boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
            }}
            onClick={onClose}
          >
            ×
          </button>
        </div>
        <div className="modal-content">
          <div className="modal-body text-center">
            <div className="row">
              {/* ABOUT Section */}
              <div className="col-sm-4 animate__animated animate__fadeInUp">
                <div
                  className="thumbnail"
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    transition: "transform 0.3s ease",
                    marginLeft:"30px"
                  }}
                  onClick={() => navigate("/about")}>
                  <img
                    src={About1}
                    alt="About"
                    style={{
                      width: "100%",
                      height: "250px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                  <div className="caption" style={{ marginTop: "10px" }}>
                    <h4 style={{ fontWeight: "bold" }}>ABOUT</h4>
                  </div>
                </div>
              </div>

              {/* SERVICES Section */}
              <div className="col-sm-4 animate__animated animate__fadeInUp animate__delay-1s">
                <div
                  className="thumbnail"
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    transition: "transform 0.3s ease",
                  }}
                  onClick={() => navigate("/service")}>
                  <img
                    src={Services}
                    alt="Services"
                    style={{
                      width: "100%",
                      height: "250px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                  <div className="caption" style={{ marginTop: "10px" }}>
                    <h4 style={{ fontWeight: "bold" }}>SERVICES</h4>
                  </div>
                </div>
              </div>

              {/* BLOG Section */}
              <div className="col-sm-4 animate__animated animate__fadeInUp animate__delay-2s">
                <div
                  className="thumbnail"
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    transition: "transform 0.3s ease",
                    marginRight:"30px"
                  }}
                  onClick={() => navigate("/blog")}>
                  <img
                    src={News}
                    alt="Blog"
                    style={{
                      width: "100%",
                      height: "250px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                  <div className="caption" style={{ marginTop: "10px" }}>
                    <h4 style={{ fontWeight: "bold" }}>BLOG</h4>
                  </div>
                </div>
              </div>
            </div>
          <br/>
            {/* FAQs Section */}
            <div className="row">
              <div className="col-sm-6 animate__animated animate__fadeInUp animate__delay-3s">
                <div
                  className="thumbnail"
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    transition: "transform 0.3s ease",
                  }}
                  onClick={() => navigate("/faq")} >
                  <img
                    src={faq}
                    alt="FAQs"
                    style={{
                      width: "50%",
                      height: "220px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      marginLeft:"30px"
                    }}
                  />
                  <div className="caption" style={{ marginTop: "10px" }}>
                    <h4 style={{ fontWeight: "bold" }}>FAQs</h4>
                  </div>
                </div>
              </div>

              {/* CONTACT US Section */}
         
              <div className="col-sm-6 animate__animated animate__fadeInUp animate__delay-4s" >
                <div
                  className="thumbnail"
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    transition: "transform 0.3s ease",
                  }}
                  onClick={() => navigate("/contact")} >
                  <img
                    src={ContactForm}
                    alt="Contact Us"
                    style={{
                      width: "40%",
                      height: "250px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      marginRight:"30px"
                    }}
                   />
                  <div className="caption" style={{ marginTop: "10px" }}>
                    <h4 style={{ fontWeight: "bold" }} >CONTACT US</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuModal;
