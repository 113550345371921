
import '../css/animate.css';
import '../css/animation.css';
import '../css/bootstrap.min.css';
import '../css/elegenticon.css';
import '../css/font-awesome.css';
import '../css/fontawesome.min.css';
import '../css/hover.css';
import '../css/icofont.min.css';
import '../css/jquery-ui.css';
import '../css/jquery.fancybox.min.css';
import '../css/jquery.mCustomScrollbar.min.css';
import '../css/linearicons.css';
import '../css/main.css';
import '../css/owl.css';
import '../css/responsive.css';
import '../css/simple-line-icons.css';
import '../css/themify-icons.css';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        
        <footer className="main-footer">
        <div className="auto-container">
            <div className="widgets-section">
                <div className="row clearfix">
                    
                    <div className="big-column col-lg-6 col-md-12 col-sm-6 col-12">
                        <div className="row clearfix">
                            
                            <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                <div className="footer-widget logo-widget">
                                    <Link to="/" className="logo"><img src={logo} alt="" title="" width={150} height={100}/></Link>
                                    <div className="call mt-2">
                                        Contact us directly
                                        <Link className="email" href="mailto:info.fund@betagroup.me">info.fund@betagroup.me</Link>
                                    </div>
                                </div>
                            </div>
    
                            <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                <div className="footer-widget links-widget">
                                    <h5>Quick Links</h5>
                                    <ul className="list-link">
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/service">Solutions</Link></li>
                                        <li><Link to="/blog">Blog</Link></li>
                                        <li><Link to="/faq">FAQs</Link></li>
                                        <li><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
    
                        </div>
                    </div>
    
                    <div className="big-column col-lg-6 col-md-12 col-sm-6 col-12">
                        <div className="row clearfix">
    
                            <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                <div className="footer-widget links-widget">
                                    <h5>Follow Us</h5>
                                    <div className="footer-bottom">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <div className="bottom-inner row clearfix">
    <div className="col-lg-4 col-md-12 col-sm-12">
      <ul style={{ display: 'flex', gap: '20px' }}>
        <li style={{ position: 'relative' }}>
          <Link
            to="http://facebook.com/"
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              transition: 'background-color 0.3s ease, border-color 0.3s ease',
              textDecoration: 'none',
              color: 'inherit',
              position: 'relative',
            }}
            aria-label="Facebook"
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#1877f2';
              e.currentTarget.style.color = 'white';
              e.currentTarget.style.borderColor = '#1877f2';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f9f9f9';
              e.currentTarget.style.color = 'inherit';
              e.currentTarget.style.borderColor = '#ccc';
            }}
          >
            <i className="icofont-facebook" style={{ fontSize: '24px' }}></i>
            <span style={{
              position: 'absolute',
              bottom: '60px',
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '5px 10px',
              backgroundColor: '#000',
              color: 'white',
              borderRadius: '5px',
              opacity: 0,
              visibility: 'hidden',
              fontSize: '12px',
              whiteSpace: 'nowrap',
              transition: 'opacity 0.3s ease, visibility 0.3s ease'
            }}>Facebook</span>
          </Link>
        </li>
        
        <li style={{ position: 'relative' }}>
          <Link
            to="https://twitter.com/"
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              transition: 'background-color 0.3s ease, border-color 0.3s ease',
              textDecoration: 'none',
              color: 'inherit',
              position: 'relative',
            }}
            aria-label="Twitter"
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#1da1f2';
              e.currentTarget.style.color = 'white';
              e.currentTarget.style.borderColor = '#1da1f2';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f9f9f9';
              e.currentTarget.style.color = 'inherit';
              e.currentTarget.style.borderColor = '#ccc';
            }}
          >
            <i className="icofont-twitter" style={{ fontSize: '24px' }}></i>
            <span style={{
              position: 'absolute',
              bottom: '60px',
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '5px 10px',
              backgroundColor: '#000',
              color: 'white',
              borderRadius: '5px',
              opacity: 0,
              visibility: 'hidden',
              fontSize: '12px',
              whiteSpace: 'nowrap',
              transition: 'opacity 0.3s ease, visibility 0.3s ease'
            }}>Twitter</span>
          </Link>
        </li>
        <li style={{ position: 'relative' }}>
          <Link
            to="https://instagram.com"
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              transition: 'background-color 0.3s ease, border-color 0.3s ease',
              textDecoration: 'none',
              color: 'inherit',
              position: 'relative',
            }}
            aria-label="Instagram"
            onMouseEnter={(e) => {
            e.currentTarget.style.background = '#dd2a7b';
              e.currentTarget.style.color = 'white';
             e.currentTarget.style.background = '#dd2a7b';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f9f9f9';
              e.currentTarget.style.color = 'inherit';
              e.currentTarget.style.borderColor = '#ccc';
            }}
          >
            <i className="icofont-instagram" style={{ fontSize: '24px', color: 'inherit' }}></i>
            <span style={{
              position: 'absolute',
              bottom: '60px',
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '5px 10px',
              backgroundColor: '#000',
              color: 'white',
              borderRadius: '5px',
              opacity: 0,
              visibility: 'hidden',
              fontSize: '12px',
              whiteSpace: 'nowrap',
              transition: 'opacity 0.3s ease, visibility 0.3s ease'
            }}>Instagram</span>
          </Link>
        </li>

        <li style={{ position: 'relative' }}>
          <Link
            to="https://wa.me/96176157762"
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              transition: 'background-color 0.3s ease, border-color 0.3s ease',
              textDecoration: 'none',
              color: 'inherit',
              position: 'relative',
            }}
            aria-label="WhatsApp"
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#25d366';
              e.currentTarget.style.color = 'white';
              e.currentTarget.style.borderColor = '#25d366';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#f9f9f9';
              e.currentTarget.style.color = 'inherit';
              e.currentTarget.style.borderColor = '#ccc';
            }}
          >
            <i className="icofont-whatsapp" style={{ fontSize: '24px', color: 'inherit' }}></i>
            <span style={{
              position: 'absolute',
              bottom: '60px',
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '5px 10px',
              backgroundColor: '#000',
              color: 'white',
              borderRadius: '5px',
              opacity: 0,
              visibility: 'hidden',
              fontSize: '12px',
              whiteSpace: 'nowrap',
              transition: 'opacity 0.3s ease, visibility 0.3s ease'
            }}>WhatsApp</span>
          </Link>
        </li>
      </ul>
    </div>
  </div>
</div>

            </div>
                                </div>
                            </div>
    
                            <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                <div className="footer-widget newsletter-widget">
                                    <h5>Our Newsletter</h5>
                                    <div className="text">Subscribe to our newsletter and we will inform you about latest updates and offers</div>
                                    <form method="post" action="/contact" className="newsletter-form">
                                        <div className="form-group">
                                            <input type="email" name="email" placeholder="Email Address..." required/>
                                            <button type="submit" className="theme-btn icofont-arrow-right"></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            
                        </div>
                    </div>
    
                </div>
            </div>
            
        </div>
    </footer>
    
    
    );
}
