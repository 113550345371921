import React from 'react';
import {useNavigate} from 'react-router-dom';

const FAQ_Box = ({ allcategories }) => {
  const navigate = useNavigate();
  
  return (
    <div
  className="col"
  onClick={() => {
    navigate(`/faq/children/${allcategories.faq_category_id}`);
  }}
  style={{
    width: '300px',
    height: '300px',
  }}
>

  <div className="card h-100 text-center" style={{ border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: 'auto',fontSize:'14px' }}>
    <div className="card-body d-flex flex-column justify-content-center align-items-center">
      <img
        src={allcategories.image}
        className="img-fluid mb-3"
        alt="Category Image"
        style={{ width: '80px', height: 'auto' }} // Correct inline styles
      />
      <h4 className="card-title" style={{fontWeight:'bold',fontFamily: 'Arial',color:'black'}}>{allcategories.title}</h4>
      <p className="card-text" style={{ color: 'black'}}>{allcategories.subtitle}</p>
    </div>
  </div>
</div>

  );
};

export default FAQ_Box;
