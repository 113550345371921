import React, { useEffect, useState ,useRef} from 'react';
import logo from '../assets/logo.png';
import PhoneInput from "react-phone-number-input";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CountryDropdown } from 'react-country-region-selector';
import Select from 'react-select';

import { useAddOnBoardingFormDataMutation} from '../redux/api/onboarding_form';

function OnBoardingForm() {

    const [countries, setCountries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);  // State to store any error message
    const [hasFetched, setHasFetched] = useState(false);  // Track if data has been fetched
  
    // Fetch country data from the new API
    useEffect(() => {
      if (!hasFetched) {
        fetchCountries();
      }
    }, [hasFetched]);
  
    const fetchCountries = async () => {
      setIsLoading(true);
      setError(null);  // Reset error before starting the fetch
      try {
        // Using the new API: https://countriesnow.space/api/v0.1/countries
        const response = await axios.get('https://countriesnow.space/api/v0.1/countries', {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        // Extract country names from the response data
        const countryOptions = response.data.data.map((country) => ({
          label: country.country,  // The country name
          value: country.country,  // Use the country name as the value
        }));
  
        setCountries(countryOptions);
        setHasFetched(true);  // Set the state to indicate that countries have been fetched
      } catch (error) {
        console.error('Error fetching countries:', error);
        setError('Failed to load country data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleCountryChange = (selectedOption) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        nationality: selectedOption ? selectedOption.value : '', // Update nationality
      }));
    };
  useEffect(() => {
    import('../../src/assets/style.css');
  }, []);
  const [isTwoImages, setIsTwoImages] = useState(false);
  const [isTwoResidencyImages, setIsTwoResidencyImages] = useState(false);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePrev = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1)); 
  };
  const [formData, setFormData] = useState({
    name:'',
    gender:'',
    date_of_birth:'',
    nationality:'',
    email: '',
    telephone:'',
    residential_address:'',
    tax_residency:'',
    us_citizen:'',
    occupation:'',
    workplace:'',
    professional_experience:'',
    source_of_income_funds:'',
    annual_income_range:'',
    investment_objectives:'',
    risk_tolerance:'',
    government_issued_identification_img1:'',
    government_issued_identification_img2:'',
    proof_of_residency_img1:'',
     proof_of_residency_img2:''
  });

  const handleNationalityChange = (nationality) => {
    setFormData({
      ...formData,
      nationality: nationality,  
    });
  };
  const handleNext = () => {
    const newErrors = {};
    if (step === 1) {
  
        const nameError = validateName(formData.name);
        if (nameError) {
          newErrors.name = nameError;
        }
        const emailError = validateEmail(formData.email);
  if (emailError) {
    newErrors.email = emailError;
  }
 
        if (!formData.telephone) {
          newErrors.telephone = 'Phone Number is required';
        } 
        if (!formData.nationality) {
          newErrors.nationality = 'Nationality is required';
        } 
        
        if (!formData.gender) {
          newErrors.gender = 'Gender is required';
        }

        if (!formData.date_of_birth) {
            newErrors.date_of_birth = 'Date of Birth is required';
          }
          if (!formData.residential_address) {
            newErrors.residential_address = 'Residential Address is required';
          }
        
          // Tax Residency validation
          if (!formData.tax_residency) {
            newErrors.tax_residency = 'Tax Residency is required';
          }
        
          // US Citizen validation
          if (!formData.us_citizen) {
            newErrors.us_citizen = 'US Citizenship status is required';
          }
      }
  
    
  
      if (step === 2) {

        if (!formData.occupation) {
          newErrors.occupation = 'Occupation is required';
        }
    
        if (!formData.workplace) {
          newErrors.workplace = 'Workplace is required';
        }
      
        if (!formData.professional_experience) {
          newErrors.professional_experience = 'Professional experience is required';
        }
      
        if (!formData.source_of_income_funds) {
          newErrors.source_of_income_funds = 'Source of income/funds is required';
        }

        if (!formData.annual_income_range) {
          newErrors.annual_income_range = 'Annual income range is required';
        }
      
        if (!formData.investment_objectives) {
          newErrors.investment_objectives = 'Investment objectives are required';
        }
      
        if (!formData.risk_tolerance) {
          newErrors.risk_tolerance = 'Risk tolerance is required';
        }
      }

      
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setErrors({});
        setStep((prevStep) => Math.min(prevStep + 1, 5));
      }
  };




  const [errors, setErrors] = useState({});
  const [addOnBoardingFormData] = useAddOnBoardingFormDataMutation();
  const [errorMessage, setErrorMessage] = useState('');

  
 const handleChange = (event) => {
      const { name, value, type, checked } = event.target;

      if (type === 'checkbox') {
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked ? 'Yes' : 'No',
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
  };
  
  const validateEmail = (email) => {
    if (!email) return 'Email is required with valid one like example@example.com';
    return '';
  };
  
  
  
  const resetForm = () => {
    setFormData({
        name:'',
        gender:'',
        date_of_birth:'',
        nationality:'',
        email: '',
        telephone:'',
        residential_address:'',
        tax_residency:'',
        us_citizen:'',
        occupation:'',
        workplace:'',
        professional_experience:'',
        source_of_income_funds:'',
        annual_income_range:'',
        investment_objectives:'',
        risk_tolerance:'',
        government_issued_identification_img1:'',
        government_issued_identification_img2:'',
        proof_of_residency_img1:'',
         proof_of_residency_img2:''
    });
  };
  const validateName = (name) => {
    if (!name) return 'Name is required';
    if (!/^[a-zA-Z\s]+$/.test(name)) return 'Name can only contain letters and spaces';
    return '';
  };
  
  
  const validatePhone = (telephone) => {
      if (!telephone) {
        return 'telephone number cannot be empty';
      }
      const telephoneRegex = /^(?:\+?\d{1,4})?[\s-]?(\(?\d{1,4}\)?[\s-]?)?\d{1,4}[\s-]?\d{1,4}[\s-]?\d{1,4}$/;    // Example regex for US telephone numbers (10 digits, starting with 2-9)
      if (!telephoneRegex.test(telephone)) {
        return 'Invalid phone number format';
      }
    return ''; 
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (step === 3) {
        if (!formData.government_issued_identification_img1) {
          newErrors.government_issued_identification_img1 = 'Government issued identification image is required';
        }
        if (!formData.proof_of_residency_img1) {
          newErrors.proof_of_residency_img1 = 'Proof of residency image is required';
        }
        if (isTwoImages && !formData.government_issued_identification_img2) { 
          newErrors.government_issued_identification_img2 = 'Government issued identification second image is required in National ID';
        }
      }
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      }
      else{
        try {
            await addOnBoardingFormData(formData).unwrap();
            setIsFormSubmitted(true);
            resetForm(); 
            
        } catch (error) {
            console.error('Failed to submit form:', error);
        }
      }
};

  
  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleKeyUp = (e) => {
    handleChange(e);
    validateField(e);
  };


  const validateField = (e) => {
    const { name, value } = e.target;
    let error = '';
    
    if (name === 'name') {
      error = validateName(value);
    }
   
    else if (name === 'email') {
      error = validateEmail(value);
    }
 
    else if (name === 'telephone') {
      error = validatePhone(value);
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };


  const [uploadData, setUploadData] = useState({
    imageFile: null, 
    imagePreview: null, 
  });

  const [formErrors, setFormErrors] = useState({
    image: '',
  });

 
  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Validate file type and size
    if (file) {
      const isValidType = ['image/jpeg', 'image/png'].includes(file.type);
      const isValidSize = file.size <= 500 * 1024; // 500KB

      if (!isValidType) {
        setFormErrors({ image: 'Invalid file type. Only JPEG and PNG are allowed.' });
        return;
      }

      if (!isValidSize) {
        setFormErrors({ image: 'File size should be less than 500KB.' });
        return;
      }

      // Generate image preview if file is valid
      const previewUrl = URL.createObjectURL(file);
      setUploadData({
        ...uploadData,
        [name]: file,  // Store the selected file
        imagePreview: previewUrl,  // Set image preview URL
      });

      await handleFormSubmit(file);
    }
  };


  const handleFormSubmit = async (file) => {
    const formDataToSend = new FormData();
    formDataToSend.append('file', file); 

    try {
      const response = await axios.post('https://control.betagroup.me/index.php?route=tool/upload&customer=1', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
     
        setFormData(prevData => ({
            ...prevData,
            government_issued_identification_img1: response.data.image
          }));
        setFormErrors({ image: '' });
      } else {
     
        setFormErrors({ image: 'Error uploading image' });
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setFormErrors({ image: 'Error uploading image' });
    }
  };

  const [uploadDataImg2, setUploadDataImg2] = useState({
    imageFile: null, 
    imagePreviewImg2: null, 
  });

  const [formErrorsImg2, setFormErrorsImg2] = useState({
    image: '',
  });

 
  const handleFileChangeImg2 = async (e) => {
    const { name, files } = e.target;
    const file = files[0];

 
    if (file) {
      const isValidType = ['image/jpeg', 'image/png'].includes(file.type);
      const isValidSize = file.size <= 500 * 1024;

      if (!isValidType) {
        setFormErrorsImg2({ image: 'Invalid file type. Only JPEG and PNG are allowed.' });
        return;
      }

      if (!isValidSize) {
        setFormErrorsImg2({ image: 'File size should be less than 500KB.' });
        return;
      }

      const previewUrl = URL.createObjectURL(file);
      setUploadDataImg2({
        ...uploadDataImg2,
        [name]: file,  
        imagePreviewImg2: previewUrl, 
      });

      await handleFormSubmitImg2(file);
    }
  };


  const handleFormSubmitImg2 = async (file) => {
    const formDataToSend = new FormData();
    formDataToSend.append('file', file); 

    try {
      const response = await axios.post('https://control.betagroup.me/index.php?route=tool/upload&customer=1', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
     
        setFormData(prevData => ({
            ...prevData,
            government_issued_identification_img2: response.data.image
          }));
        setFormErrorsImg2({ image: '' });
      } else {
     
        setFormErrorsImg2({ image: 'Error uploading image' });
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setFormErrorsImg2({ image: 'Error uploading image' });
    }
  };

  const [uploadData1, setUploadData1] = useState({
    imageFile1: null, 
    imagePreview1: null, 
  });


 
  const handleFileChange1 = async (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Validate file type and size
    if (file) {
      const isValidType = ['image/jpeg', 'image/png'].includes(file.type);
      const isValidSize = file.size <= 500 * 1024; // 500KB

      if (!isValidType) {
        setFormErrors({ image: 'Invalid file type. Only JPEG and PNG are allowed.' });
        return;
      }

      if (!isValidSize) {
        setFormErrors({ image: 'File size should be less than 500KB.' });
        return;
      }

      // Generate image preview if file is valid
      const previewUrl = URL.createObjectURL(file);
      setUploadData1({
        ...uploadData,
        [name]: file,  // Store the selected file
        imagePreview1: previewUrl,  // Set image preview URL
      });

      await handleFormSubmit1(file);
    }
  };

  const handleFormSubmit1 = async (file) => {
    const formDataToSend = new FormData();
    formDataToSend.append('file', file); 

    try {
      const response = await axios.post('https://control.betagroup.me/index.php?route=tool/upload&customer=1', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
     
        setFormData(prevData => ({
            ...prevData,
            proof_of_residency_img1: response.data.image
          }));
        setFormErrors({ image: '' });
      } else {
     
        setFormErrors({ image: 'Error uploading image' });
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setFormErrors({ image: 'Error uploading image' });
    }
  };

  const [uploadData1Img2, setUploadData1Img2] = useState({
    imageFile1Img2: null, 
    imagePreview1Img2: null, 
  });


 
  const handleFileChange1Img2 = async (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Validate file type and size
    if (file) {
      const isValidType = ['image/jpeg', 'image/png'].includes(file.type);
      const isValidSize = file.size <= 500 * 1024; // 500KB

      if (!isValidType) {
        setFormErrors({ image: 'Invalid file type. Only JPEG and PNG are allowed.' });
        return;
      }

      if (!isValidSize) {
        setFormErrors({ image: 'File size should be less than 500KB.' });
        return;
      }

     
      const previewUrl = URL.createObjectURL(file);
      setUploadData1Img2({
        ...uploadData1Img2,
        [name]: file,  
        imagePreview1Img2: previewUrl, 
      });

      await handleFormSubmit1Img2(file);
    }
  };

  const handleFormSubmit1Img2 = async (file) => {
    const formDataToSend = new FormData();
    formDataToSend.append('file', file); 

    try {
      const response = await axios.post('https://control.betagroup.me/index.php?route=tool/upload&customer=1', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
     
        setFormData(prevData => ({
            ...prevData,
            proof_of_residency_img2: response.data.image
          }));
        setFormErrors({ image: '' });
      } else {
     
        setFormErrors({ image: 'Error uploading image' });
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setFormErrors({ image: 'Error uploading image' });
    }
  };

  return (
    <>
      <div className="wrapper position-relative">
        <div className="wizard-content-1 clearfix">
          <div className="steps d-inline-block position-absolute clearfix">
            <ul className="tablist multisteps-form__progress">
              <li
                className={`multisteps-form__progress-btn ${step >= 1 ? 'js-active current' : ''}`}
              ></li>
              <li
                className={`multisteps-form__progress-btn ${step >= 2 ? 'js-active current' : ''}`}
              ></li>
              <li
                className={`multisteps-form__progress-btn ${step >= 3 ? 'js-active current' : ''}`}
              ></li>
              
            </ul>
          </div>
          <div className="step-inner-content clearfix position-relative">
            <form className="multisteps-form__form" id="wizard"  onSubmit={handleSubmit} >
              <div className="form-area position-relative">
                

                {/* Step 1 */}
                {step === 1 && (
                  <div className="multisteps-form__panel js-active" data-animation="fadeIn">
                    <div className="wizard-forms position-relative">
                      <div className="job-style-two-content d-flex">
                        <div className="job-wizard-left-area position-relative">
                          <div className="job-wizard-left-text">
                          <img src={logo}/>
                            <h1 className='mt-4'>Start Your Journey Now!</h1>
                            <p style={{marginBottom:isLargeScreen?"500px":""}}>
                            Welcome to Beta Investment! To open and activate your investment account, we kindly ask you to complete this form with your personal and financial details. The information provided will allow us to tailor our services to meet your investment goals and comply with regulatory requirements. Please ensure all details are accurate and up-to-date. Your privacy and data security are our top priorities.
                            </p>
                          </div>
                        </div>
                        <div className="job-wizard-right-area">
                          <div className="jw-top-title">
                            <h2>OnBoarding Form</h2>
                            <p>Personal info</p>
                          </div>
                          <div className="hire-company-form">
                          <label htmlFor="name" style={{ fontWeight: 'bold',fontSize:'18px',color:'black' }}>
             Full Name   {errors.name && <span className="error" style={{color:"red",fontWeight:"normal"}}>{errors.name}</span>}
          </label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                className="form-control mt-2 p-3"
                                placeholder="Enter your Full Name"
                                required
                                value={formData.name}
                                onKeyUp={handleKeyUp}
                                onChange={handleChange}
                            />
                             <label htmlFor="date_of_birth" style={{ fontWeight: 'bold',fontSize:'18px',color:'black' }}>
           Date Of Birth {errors.date_of_birth && <span className="error" style={{color: "red",fontWeight:"normal"}}>{errors.date_of_birth}</span>}
          </label>
                              <input
                                type="date"
                                name="date_of_birth"
                                id="date_of_birth"
                                className="form-control mt-2 p-3"
                                required
                                value={formData.date_of_birth}
                                onKeyUp={handleKeyUp}
                                onChange={handleChange}
                            />
                            <label htmlFor="nationality" style={{ fontWeight: 'bold',fontSize:'18px',color:'black' }}>
             Nationality {errors.nationality && <span className="error" style={{color:"red",fontWeight:"normal"}}>{errors.nationality}</span>}
          </label>
          {isLoading && <p>Loading countries...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>} 
          <Select
  onChange={handleCountryChange}
  name="nationality"
  id="nationality"
  options={countries}
  value={countries.find((country) => country.value === formData.nationality) || null}
  onKeyUp={(e) => console.log('KeyUp event triggered:', e)}
  isLoading={isLoading}
  placeholder="Select Your Nationality"
  getOptionLabel={(e) => e.label}
  getOptionValue={(e) => e.value}
  isClearable
  styles={{
    control: (base) => ({
      ...base,
      backgroundColor: '#F0F3F3',
      padding: '0', 
      maxHeight: '58px', 
      borderRadius: '4px', 
    }),
    input: (base) => ({
      ...base,
      padding: '0',
      margin: '0',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '0',
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: '0',
    }),
  }}
/>






  
                            
                                 <label htmlFor="email" style={{ fontWeight: 'bold',fontSize:'18px',color:'black' }}>
             Email Address  {errors.email && <span className="error" style={{color:"red",fontWeight:"normal"}}>{errors.email}</span>}
          </label>
                                     <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="form-control mt-2 p-3"
                                    placeholder="you@example.com"
                                    required
                                    value={formData.email}
                                    onKeyUp={handleKeyUp}
                                    onChange={handleChange}
                                    />
         <label htmlFor="telephone" style={{ fontWeight: 'bold',fontSize:'18px',color:'black' }}>
             Phone Number {errors.telephone && <span className="error" style={{color:"red",fontWeight:"normal"}}>{errors.telephone}</span>}
          </label>
                            <PhoneInput
                                international
                                name="telephone"
                                id="telephone"
                                className='mt-2 p-1'
                                defaultCountry="LB"
                                value={formData.telephone}
                                onChange={handlePhoneChange}
                                onKeyUp={handleKeyUp}
                               
                            
                            />
         


<label htmlFor="residentialAddress" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
                  Residential Address  {errors.residential_address && <span className="error" style={{color: "red",fontWeight:"normal"}}>{errors.residential_address}</span>}
                </label>
                <input
                  type="text"
                  name="residential_address"
                  id="residential_address"
                  className="form-control mt-2 p-3"
                  placeholder="Enter your residential address"
                  required
                  value={formData.residential_address}
                   onKeyUp={handleKeyUp}
                                onChange={handleChange}
                />
               
<label htmlFor="taxResidency" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
                  Tax Residency {errors.tax_residency && <span className="error" style={{color: "red",fontWeight:"normal"}}>{errors.tax_residency}</span>}
                </label>
                <input
                  type="text"
                  name="tax_residency"
                  id="tax_residency"
                  className="form-control mt-2 p-3"
                  placeholder="Enter your tax residency"
                  required
                  value={formData.tax_residency}
                   onKeyUp={handleKeyUp}
                                onChange={handleChange}
                />

<div className="jw-gender-select position-relative">
                            <h3 className='text-black'>  Are you a U.S. Citizen? {errors.us_citizen && <p style={{ color: 'red', textAlign: 'center' ,fontWeight:"normal"}}>{errors.us_citizen}</p>}</h3>
                            <label>
                            <input
                                type="checkbox"
                                name="us_citizen1"
                                value="Yes"
                                id="yes"
                                checked={formData.us_citizen === 'Yes'}
                                onKeyUp={handleKeyUp}
                                onChange={(e) => {
                                if (e.target.checked) {
                                    setFormData(prev => ({ ...prev, us_citizen: 'Yes' }));
                                } else {
                                    
                                }
                                }}
                                style={{
                                transform: 'scale(0.8)'
                                }}
                            />
                            <span className="checkmark">Yes</span>
                            </label>

                            <label>
                             <input
                                type="checkbox"
                                name="us_citizen"
                                 value="No"
                                checked={formData.us_citizen === 'No'}
                                onKeyUp={handleKeyUp}
                                onChange={(e) => {
                                    handleChange(e);
                                    if (e.target.checked) {
                                      setFormData(prev => ({ ...prev, us_citizen: 'No' }));
                                    } else {
                                      return ''; 
                                    }
                                  }}
                                  
                                style={{
                                    transform: 'scale(0.8)'
                                  }}
                                />
                              <span className="checkmark">No</span>
                            </label>
                            

                          </div>
                          </div>
                          <div className="jw-gender-select position-relative">
                            <h3 className='text-black'>Gender {errors.gender && <p style={{ color: 'red', textAlign: 'center' ,fontWeight:"normal"}}>{errors.gender}</p>}</h3>
                            <label>
                             <input
                                type="radio"
                                name="gender"
                                value="Male"
                                checked={formData.gender === 'Male'}
                                onChange={handleChange}
                                />
                              <span className="checkmark">Male</span>
                            </label>
                            <label>
                            <input
                                type="radio"
                                name="gender"
                                value="Female"
                                checked={formData.gender === 'Female'}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                />
   
                              <span className="checkmark">Female</span>
                            </label>
                            

                          </div>
                         
                          <div className="actions">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <button
                                type="button"
                                onClick={handleNext}
                                style={{
                                  padding: '10px 15px',
                                  borderRadius: '5px',
                                  backgroundColor: '#13264C',
                                  color: '#fff',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Step 2 */}
                {step === 2 && (
                  <div className="multisteps-form__panel js-active" data-animation="fadeIn">
                    <div className="wizard-forms position-relative">
                      <div className="job-style-two-content d-flex">
                        <div className="job-wizard-left-area position-relative">
                          <div className="job-wizard-left-text">
                          <img src={logo}/>
                          <h1 className='mt-4'>Start Your Journey Now!</h1>
                            <p>
                            Welcome to Beta Investment! To open and activate your investment account, we kindly ask you to complete this form with your personal and financial details. The information provided will allow us to tailor our services to meet your investment goals and comply with regulatory requirements. Please ensure all details are accurate and up-to-date. Your privacy and data security are our top priorities.
                            </p>
                          </div>
                        </div>
                        <div className="job-wizard-right-area">
                          <div className="jw-top-title">
                            <h2>OnBoarding Form</h2>
                            <p>Employment, Professional & Financial Details</p>
                          </div>
                          <div className="container">
  {/* Occupation Field */}
  <div className="form-group">
    <label htmlFor="occupation" className="control-label" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
      Occupation
      {errors.occupation && (
        <span className="text-danger" style={{ marginLeft: '10px' ,fontWeight:"normal"}}>
          {errors.occupation}
        </span>
      )}
    </label>
    <input
      type="text"
      name="occupation"
      id="occupation"
      className="form-control mt-2 mb-2 p-3"
      placeholder="Enter your Occupation"
      required
      value={formData.occupation}
      onKeyUp={handleKeyUp}
      onChange={handleChange}
    />
  </div>

  {/* Workplace Field */}
  <div className="form-group">
    <label htmlFor="workplace" className="control-label" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
      Workplace
      {errors.workplace && (
        <span className="text-danger" style={{ marginLeft: '10px',fontWeight:"normal" }}>
          {errors.workplace}
        </span>
      )}
    </label>
    <input
      type="text"
      name="workplace"
      id="workplace"
      className="form-control mt-2 mb-3 p-3"
      placeholder="Enter your Workplace"
      required
      value={formData.workplace}
      onKeyUp={handleKeyUp}
      onChange={handleChange}
    />
  </div>

  {/* Professional Experience Field */}
  <div className="form-group">
    <label htmlFor="professional_experience" className="control-label" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
      Professional Experience
      {errors.professional_experience && (
        <span className="text-danger" style={{ marginLeft: '10px',fontWeight:"normal" }}>
          {errors.professional_experience}
        </span>
      )}
    </label>
    <select
      name="professional_experience"
      id="professional_experience"
      className="form-control mt-2 mb-3 p-3"
      required
      value={formData.professional_experience}
      onChange={handleChange}
    >
      <option>Select Your Professional Experience Years</option>
      <option value="0-3">0-3 years</option>
      <option value="3-5">3-5 years</option>
      <option value="5-10">5-10 years</option>
      <option value="10+">10+ years</option>
    </select>
  </div>

  {/* Source of Income/Funds Field */}
  <div className="form-group">
    <label htmlFor="source_of_income_funds" className="control-label" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
      Source of Income/Funds
      {errors.source_of_income_funds && (
        <span className="text-danger" style={{ marginLeft: '10px',fontWeight:"normal" }}>
          {errors.source_of_income_funds}
        </span>
      )}
    </label>
    <select
      name="source_of_income_funds"
      id="source_of_income_funds"
      className="form-control mt-2 mb-3 p-3"
      required
      value={formData.source_of_income_funds}
      onChange={handleChange}
    >
      <option value="">Select Your Source of Income/Funds</option>
      <option value="salary">Salary</option>
      <option value="business">Business / Self-employment</option>
      <option value="inheritance">Inheritance</option>
      <option value="savings">Savings</option>
    </select>
  </div>

  {/* Annual Income Range Field */}
  <div className="form-group">
    <label htmlFor="annual_income_range" className="control-label" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
      Annual Income Range
      {errors.annual_income_range && (
        <span className="text-danger" style={{ marginLeft: '10px',fontWeight:"normal" }}>
          {errors.annual_income_range}
        </span>
      )}
    </label>
    <select
      name="annual_income_range"
      id="annual_income_range"
      className="form-control mt-2 mb-3 p-3"
      required
      value={formData.annual_income_range}
      onChange={handleChange}
    >
      <option value="">Select Your Annual Income Range</option>
      <option value="$0-$20,000">$0 – $20,000</option>
      <option value="$20,001-$50,000">$20,001 – $50,000</option>
      <option value="$50,001-$100,000">$50,001 – $100,000</option>
      <option value="$100,001-$250,000">$100,001 – $250,000</option>
      <option value="$250,001-$500,000">$250,001 – $500,000</option>
      <option value="$500,001-$1,000,000">$500,001 – $1,000,000</option>
      <option value="Over $1,000,000">Over $1,000,000</option>
    </select>
  </div>

  {/* Investment Objectives Field */}
  <div className="form-group">
    <label htmlFor="investment_objectives" className="control-label" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
      Investment Objectives
      {errors.investment_objectives && (
        <span className="text-danger" style={{ marginLeft: '10px',fontWeight:"normal" }}>
          {errors.investment_objectives}
        </span>
      )}
    </label>
    <select
      name="investment_objectives"
      id="investment_objectives"
      className="form-control mt-2 mb-3 p-3"
      required
      value={formData.investment_objectives}
      onChange={handleChange}
    >
      <option value="">Select Your Investment Objectives</option>
      <option value="incomeGeneration">Income generation</option>
      <option value="wealthGrowth">Wealth growth</option>
      <option value="savingsPreservation">Savings and preservation</option>
      <option value="financing">Financing</option>
      <option value="retirement">Retirement</option>
    </select>
  </div>

  {/* Risk Tolerance Field */}
  <div className="form-group">
    <label htmlFor="risk_tolerance" className="control-label" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
      Risk Tolerance
      {errors.risk_tolerance && (
        <span className="text-danger" style={{ marginLeft: '10px',fontWeight:"normal" }}>
          {errors.risk_tolerance}
        </span>
      )}
    </label>
    <select
      name="risk_tolerance"
      id="risk_tolerance"
      className="form-control mt-2 p-3"
      required
      value={formData.risk_tolerance}
      onChange={handleChange}
    >
      <option value="">Select Your Risk Tolerance</option>
      <option value="conservative">Conservative 10%</option>
      <option value="moderate">Moderate 20%</option>
      <option value="growth">Growth 30%</option>
    </select>
  </div>
</div>

                       
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                              <button
                                type="button"
                                onClick={handlePrev}
                                style={{
                                  padding: '10px 20px',
                                  borderRadius: '5px',
                                  backgroundColor: '#888',
                                  color: '#fff',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Previous
                              </button>
                              <button
                                type="button"
                                onClick={handleNext}
                                style={{
                                  padding: '10px 20px',
                                  borderRadius: '5px',
                                  backgroundColor: '#13264C',
                                  color: '#fff',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Next
                              </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Step 3 */}
                {step === 3 && !isFormSubmitted && (
                  <div className="multisteps-form__panel js-active" data-animation="fadeIn">
                    <div className="wizard-forms position-relative">
                      <div className="job-style-two-content d-flex">
                        <div className="job-wizard-left-area position-relative">
                          <div className="job-wizard-left-text">
                            <img src={logo}/>
                            <h1 className='mt-4'>Start Your Journey Now!</h1>
                            <p>
                            Welcome to Beta Investment! To open and activate your investment account, we kindly ask you to complete this form with your personal and financial details. The information provided will allow us to tailor our services to meet your investment goals and comply with regulatory requirements. Please ensure all details are accurate and up-to-date. Your privacy and data security are our top priorities.
                            </p>
                          </div>
                        </div>
                        <div className="job-wizard-right-area">
                          <div className="jw-top-title">
                            <h2>OnBoarding Form</h2>
                            <p>Regulatory and Compliance Information</p>
                          </div>
                          <div className="container">
      {/* Government Issued Identification Images */}
      <div className="form-group">
        <label className="control-label" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
          Select Government Issued Identification Image Type {errors.government_issued_identification_img1 && <span className="error" style={{color:"red",fontWeight:"normal"}}>{errors.government_issued_identification_img1}</span>}
        </label>
        <div className="form-check">
          <input
            type="radio"
            id="imageOption1"
            name="imageOption"
            className="form-check-input"
            checked={!isTwoImages}
            onChange={() => setIsTwoImages(false)}
          />
          <label className="form-check-label" htmlFor="imageOption1" style={{ fontWeight: 'normal', fontSize: '16px', color: 'black' }}>
            One Image (e.g. Driver’s License / Passport)
          </label>
        </div>
        <div className="form-check">
          <input
            type="radio"
            id="imageOption2"
            name="imageOption"
            className="form-check-input"
            checked={isTwoImages}
            onChange={() => setIsTwoImages(true)}
          />
          <label className="form-check-label" htmlFor="imageOption2" style={{ fontWeight: 'normal', fontSize: '16px', color: 'black' }}>
            Two Images (e.g. National ID)
          </label>
        </div>
      </div>

      {/* Government Issued Identification Image 1 */}
      <div className="form-group">
        <label htmlFor="government_issued_identification_img1" className="control-label" style={{ fontSize: '18px', color: 'black' }}>
          Government Issued Identification Image 1
        </label>
        <input
          type="file"
          name="government_issued_identification_img1"
          id="government_issued_identification_img1"
          accept="image/jpeg, image/png"
          onChange={handleFileChange}
          required
          className="form-control mt-2 mb-2"
        />
        {/* Image Preview */}
        {uploadData.imagePreview && (
          <div className="mt-3">
            <img
              src={uploadData.imagePreview}
              alt="Preview"
              className="img-responsive"
              style={{ maxHeight: '200px' }}
            />
          </div>
        )}
      </div>

      {/* Conditionally render the second image input based on the toggle */}
      {isTwoImages && (
        <div className="form-group">
          <label htmlFor="government_issued_identification_img2" className="control-label" style={{ fontSize: '18px', color: 'black' }}>
            Government Issued Identification Image 2 {errors.government_issued_identification_img2 && <span className="error" style={{color:"red",fontWeight:"normal"}}>{errors.government_issued_identification_img2}</span>}
          </label>
          <input
            type="file"
            name="government_issued_identification_img2"
            id="government_issued_identification_img2"
            accept="image/jpeg, image/png"
            onChange={handleFileChangeImg2}
            required
            className="form-control mt-2 mb-2"
          />
          {/* Image Preview */}
          {uploadDataImg2.imagePreviewImg2 && (
            <div className="mt-3">
              <img
                src={uploadDataImg2.imagePreviewImg2}
                alt="Preview"
                className="img-responsive"
                style={{ maxHeight: '200px' }}
              />
            </div>
          )}
        </div>
      )}

      {/* Proof of Residency Images */}
      <div className="form-group">
        <label className="control-label" style={{ fontWeight: 'bold', fontSize: '18px', color: 'black' }}>
          Select Proof of Residency Image Type {errors.proof_of_residency_img1 && <span className="error" style={{color:"red",fontWeight:"normal"}}>{errors.proof_of_residency_img1}</span>}
        </label>
        <div className="form-check">
          <input
            type="radio"
            id="residencyOption1"
            name="residencyOption"
            className="form-check-input"
            checked={!isTwoResidencyImages}
            onChange={() => setIsTwoResidencyImages(false)}
          />
          <label className="form-check-label" htmlFor="residencyOption1" style={{ fontWeight: 'normal', fontSize: '16px', color: 'black' }}>
            One Image 
          </label>
        </div>
        <div className="form-check">
          <input
            type="radio"
            id="residencyOption2"
            name="residencyOption"
            className="form-check-input"
            checked={isTwoResidencyImages}
            onChange={() => setIsTwoResidencyImages(true)}
          />
          <label className="form-check-label" htmlFor="residencyOption2" style={{ fontWeight: 'normal', fontSize: '16px', color: 'black' }}>
            Two Images 
          </label>
        </div>
      </div>

      {/* Proof of Residency Image 1 */}
      <div className="form-group">
        <label htmlFor="proof_of_residency_img1" className="control-label" style={{ fontSize: '18px', color: 'black' }}>
          Proof of Residency Image 1
        </label>
        <input
          type="file"
          name="proof_of_residency_img1"
          id="proof_of_residency_img1"
          accept="image/jpeg, image/png"
          onChange={handleFileChange1}
          required
          className="form-control mt-2 mb-2"
        />
        {/* Image Preview */}
        {uploadData1.imagePreview1 && (
          <div className="mt-3">
            <img
              src={uploadData1.imagePreview1}
              alt="Preview"
              className="img-responsive"
              style={{ maxHeight: '200px' }}
            />
          </div>
        )}
      </div>

      {/* Conditionally render the second "Proof of Residency" image input based on the toggle */}
      {isTwoResidencyImages && (
        <div className="form-group">
          <label htmlFor="proof_of_residency_img2" className="control-label" style={{ fontSize: '18px', color: 'black' }}>
            Proof of Residency Image 2
          </label>
          <input
            type="file"
            name="proof_of_residency_img2"
            id="proof_of_residency_img2"
            accept="image/jpeg, image/png"
            onChange={handleFileChange1Img2}
            required
            className="form-control mt-2 mb-2"
          />
          {/* Image Preview */}
          {uploadData1Img2.imagePreview1Img2 && (
            <div className="mt-3">
              <img
                src={uploadData1Img2.imagePreview1Img2}
                alt="Preview"
                className="img-responsive"
                style={{ maxHeight: '200px' }}
              />
            </div>
          )}
        </div>
      )}
    </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                              <button
                                type="button"
                                onClick={handlePrev}
                                style={{
                                  padding: '10px 20px',
                                  borderRadius: '5px',
                                  backgroundColor: '#888',
                                  color: '#fff',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Previous
                              </button>
                              <button
                                type="button"
                                onClick={handleSubmit}
                                style={{
                                  padding: '10px 20px',
                                  borderRadius: '5px',
                                  backgroundColor: '#3a9d6a',
                                  color: '#fff',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Submit
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isFormSubmitted && (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
    textAlign: 'center',
  }}>
    <h1 className='text-success'>Form Submitted Successfully!</h1>
    <button
      onClick={() => navigate('/')}
      style={{
        padding: '15px 30px',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '18px',
      }}
   className='bg-success mt-2' >
      Go to Home Page
    </button>
  </div>
)}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default OnBoardingForm;
