import React,{useState,useEffect} from 'react';
import {Carousel} from 'react-bootstrap';


const InvestmentPlanType = ({ selectedPlan }) => {
  
  const plans = [
    {
      name: "Conservative",
      features: [
        <span
  className="text-white bg-success"
  style={{
    backgroundColor: "#f0f0f0", 
    padding: "5px 5px",     
    borderRadius: "15px",       
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", 
    fontSize: "1rem",         
  }}
>
  <span className="text-white fw-bold">Risk Tolerance:</span> 10%
</span>,
        <span className='text-black'><span className="text-black fw-bold">Objective:</span> Prioritizes capital preservation with steady, low-volatility growth, ideal for cautious investors seeking stability.</span>,
        <span className='text-black'><span className="text-black fw-bold">Management Fee:</span> 5%</span>,
        <span className='text-black'><span className="text-black fw-bold">Performance Fee:</span> 20%</span>,
      ],
    },
    {
      name: "Moderate",
      features: [
        <span
         className="text-white bg-success"
        style={{
          padding: "5px 5px",     
          borderRadius: "15px",       
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", 
          fontSize: "1rem",         
        }}
      >
        <span className="text-white fw-bold">Risk Tolerance:</span> 20%
      </span>,
        <span className='text-black'><span className="text-black fw-bold">Objective:</span> Balances growth potential with risk, making it suited for investors seeking moderate returns without high exposure to volatility.</span>,
        <span className='text-black'><span className="text-black fw-bold">Management Fee:</span> 5%</span>,
        <span className='text-black'><span className="text-black fw-bold">Performance Fee:</span> 25%</span>,
      ],
    },
    {
      name: "Growth",
      features: [
        <span
  className="text-white bg-success"
  style={{
    padding: "5px 5px",     
    borderRadius: "15px",       
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", 
    fontSize: "1rem",         
  }}
>
  <span className="text-white fw-bold">Risk Tolerance:</span> 30%
</span>,
        <span className='text-black'><span className="text-black fw-bold">Objective:</span> Aims for maximum growth by capitalizing on higher-risk opportunities, suitable for investors with a long-term outlook and high tolerance for market fluctuations.</span>,
        <span className='text-black'><span className="text-black fw-bold">Management Fee:</span> 5%</span>,
        <span className='text-black'><span className="text-black fw-bold">Performance Fee:</span> 30%</span>,
      ],
    },
  ];
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
	  const handleResize = () => {
		setIsLargeScreen(window.innerWidth >= 1024);
	  };
	  window.addEventListener('resize', handleResize);
	  return () => {
		window.removeEventListener('resize', handleResize);
	  };
	}, []);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };


  return (
    <div
      className="container"
      style={{
        marginTop: "40px",
        marginBottom: "40px",
      }}
    >
      <h3 className="text-black text-center">Discover Your Ideal Investment</h3>
      {isLargeScreen ? (
       <div className="row">
       {plans.map((plan, index) => (
         <div
           className={`col-sm-3 animate__animated animate__fadeInUp animate__delay-${index + 3}s ${
             selectedPlan === plan.name ? "hover-border" : ""
           }`}
           key={index}
           style={{
             border: "2px solid green",
             padding: "20px",
             marginBottom: "20px",
             marginLeft: isLargeScreen?"5px":'30px', 
             marginRight: "5px", 
             borderRadius: "10px",
             marginTop:"10px",
             width:"280px",
             backgroundColor: selectedPlan === plan.name ? "#3a9d6a" : "#fff",
           }}
         >
                     <h4 className="text-center text-black" style={{ fontSize: "25px" }}>
             {plan.name === 'Conservative' && (
               <i className="fa fa-bullseye text-success" aria-hidden="true"></i>
             )}
             {plan.name === 'Moderate' && (
               <i className="fa fa-adjust text-success" aria-hidden="true"></i>
             )}
               {plan.name === 'Growth' && (
               <i className="fa fa-chart-line text-success" aria-hidden="true"></i>
             )}
             <span style={{marginLeft:"8px"}}>{plan.name}</span>
           </h4>
 
           <div>
             <h4  style={{color:selectedPlan === plan.name ? "white" : "#327f5b"}}>Features :</h4>
             <ul>
               {plan.features.map((feature, idx) => (
                 <li key={idx} className={ "text-muted p-1" }>
                   {feature}
                 </li>
               ))}
             </ul>
           </div>
         </div>
       ))}
     </div>
      ) : (
        <>
        <Carousel
          interval={5000}
          controls={false} // Hide next/prev controls
          indicators={false} // Hide indicators
          activeIndex={activeIndex}
          onSelect={handleSelect}
        >
          {plans.map((plan, index) => (
            <Carousel.Item key={index}>
              <div
                className={`${
                  selectedPlan === plan.name ? "hover-border" : ""
                }`}
                style={{
                  border: "2px solid green",
                  padding: "20px",
                  marginBottom: "20px",
                  borderRadius: "10px",
                  width: "280px",
                  margin: "0 auto", // Center the card
                  backgroundColor: selectedPlan === plan.name ? "#3a9d6a" : "#fff",
                }}
              >
                <h4
                  className="text-center text-black"
                  style={{ fontSize: "25px" }}
                >
                  {plan.name === "Conservative" && (
                    <i
                      className="fa fa-bullseye text-success"
                      aria-hidden="true"
                    ></i>
                  )}
                  {plan.name === "Moderate" && (
                    <i
                      className="fa fa-adjust text-success"
                      aria-hidden="true"
                    ></i>
                  )}
                  {plan.name === "Growth" && (
                    <i
                      className="fa fa-chart-line text-success"
                      aria-hidden="true"
                    ></i>
                  )}
                  <span style={{ marginLeft: "8px" }}>{plan.name}</span>
                </h4>
      
                <div>
                  <h4
                    style={{
                      color: selectedPlan === plan.name ? "white" : "#327f5b",
                    }}
                  >
                    Features:
                  </h4>
                  <ul>
                    {plan.features.map((feature, idx) => (
                      <li key={idx} className="text-muted p-1">
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      
        <div className="carousel-buttons text-center mt-3">
          {plans.map((plan, index) => (
            <button
              key={index}
              type="button"
              aria-label={`Slide ${index + 1}`}
              className={`btn btn-outline-success mx-1 ${
                index === activeIndex ? "active" : ""
              }`}
              onClick={() => handleSelect(index)} 
            >
              {index + 1}
            </button>
          ))}
        </div>
      </>
      
      )}
    </div>
  );
  
};

export default InvestmentPlanType;
