
import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop"; 
import About from './pages/About';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import BlogDetail from './pages/Blog_Detail';
import BlogDetail1 from './pages/Blog_Detail1';
import BlogDetail2 from './pages/Blog_Detail2';
import Service from './pages/Service';
import FAQ from './pages/FAQ';
import FAQs from './pages/FAQs';
import FAQSubCategory from './pages/FAQSubCategory';
import Wealth from './pages/wealth_managment';
import Investment from './pages/invetsment_advisory';
import Financial from './pages/financial_planing';
import Corporate from './pages/corporate_finanicial';
import OnBoardingForm from './pages/OnBoardingForm';

function App() {
	useEffect(() => {
		// List of script sources
		const scripts = [
		  "js/bootstrap.min.js",
		  "js/owl.js",
		  "js/wow.js",
		  "js/jquery-ui.js",
		  "js/script.js",
		];
	  
		const addedScripts = [];
	  
		// Function to load a script
		const loadScript = (src) => {
		  return new Promise((resolve, reject) => {
			const script = document.createElement('script');
			script.src = `${process.env.PUBLIC_URL}/${src}`;
			script.async = true;
			script.onload = () => resolve(script);
			script.onerror = () => reject(new Error(`Script load error for ${src}`));
			document.body.appendChild(script);
			addedScripts.push(script); // Save the actual script element instead of src string
		  });
		};
	  
		// Dynamically add each script to the document
		const loadScripts = async () => {
		  for (const src of scripts) {
			try {
			  await loadScript(src);
			} catch (error) {
			  console.error(error);
			}
		  }
		};
	  
		loadScripts();
	  
		return () => {
		  addedScripts.forEach((script) => {
			if (script && script.parentNode === document.body) {
			  document.body.removeChild(script); // Ensure it's a valid child before removing
			}
		  });
		};
	  }, []);	  
	  

  return (
    <>
<Router>
<ScrollToTop />
      <Routes>
	  <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
		<Route path="/contact" element={<Contact />} />
		<Route path="/blog" element={<Blog />} />
		<Route path="/blog-detail" element={<BlogDetail />} />
		<Route path="/blog-detail1" element={<BlogDetail1 />} />
		<Route path="/blog-detail2" element={<BlogDetail2 />} />
		<Route path="/service" element={<Service />} />
		<Route path="/faq" element={<FAQ />} />
		<Route path="/faq/children/:id" element={<FAQSubCategory />} />
		<Route path="/faq/:id" element={<FAQs />} />
		<Route path="/service/wealth" element={<Wealth />} />
		<Route path="/service/investment" element={<Investment />} />
		<Route path="/service/financial" element={<Financial />} />
		<Route path="/service/corporate" element={<Corporate />} />
		<Route path="/form" element={<OnBoardingForm />} />
      </Routes>
    </Router>

   </>
  );
}

export default App;
