import React, { useState ,useRef} from 'react';
import Footer from '../../src/components/footer'
import Port from '../../src/assets/port.jpg';
import Phone from '../../src/assets/phone.jpg';
import Money from '../../src/assets/news-2.jpg';
import Money1 from '../../src/assets/news-10.jpg';
import { Link } from 'react-router-dom';
import Header from '../components/header';

function BlogDetail (){
    const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);
//   const [comments, setComments] = useState([]);
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [comment, setComment] = useState('');
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (name && email && comment) {
//         setComments([...comments, { name, email, comment }]);
//         setName('');
//         setEmail('');
//         setComment('');
//     }
// };

// const handleDelete = (index) => {
//     setComments(comments.filter((_, i) => i !== index));
// };

  return (
    <>
    <div className="page-wrapper">
   <Header/>

  
  <div className="sidebar-page-container padding-top style-two">
  <div class="auto-container mt-3">
  <br/>
    <h2 className='text-black'>Problems About Social Insurance For Truck Drivers</h2>
</div>
      <div className="auto-container">
          <div className="row clearfix">
              
        
              <div className="content-side col-lg-9 col-md-12 col-sm-12">
                  <div className="blog-detail">
                      <div className="inner-box">
                          <div className="image">
                              <img src={Port} alt="" />
                          </div>
                          <div className="lower-content">
                              <div className="post-info"><span className="theme_color">business</span> -  December 14th, 2024 </div>
                              <p>Most times, ideacide happens without us even realizing it. A possible off-the-wall idea or solution appears like a blip and disappears without us even realizing. As a result, some of our best stuff is suppressed before even getting out into the world. Whether it’s because we’re too critical or because we recoil at the impending pain of change, the disruption of normalcy, self-censoring arises out of fear. Welsh novelist Sarah Waters sums it up eloquently: “Midway through writing a novel, I have regularly experienced moments of bowel-curdling terror, as I contemplate the drivel on the screen before me and see beyond it, in quick succession, the derisive reviews, the friends’ embarrassment, the failing career, the dwindling income, the repossessed house, the divorce…” We know self-censoring by many names. Carl Jung called it our “inner critic.” Michael Ray and Rochelle Myers called it the “voice of judgment” in their classic book, in Business, based on a popular course they co-taught at Stanford University the derisive reviews, the friends’ embarrassment, the failing career, the dwindling income, the repossessed </p>
                              <div className="middle-image">
                                  <img src="images/resource/news-10.jpg" alt="" />
                              </div>
                              <h4>Gathered Was Isn’t Fruitful Every</h4>
                              <p>Give void had the creature man evening two be for heaven won’t you’re may. Subdue him. Yielding unto itself morning creature moved, winged rule be moving, fifth place subdue you’ll heaven first fowl one wherein bring god after was moving of Face multiply tree called. Subdue first said made living tree you’re two beast, moved, every. Evening their us seas.</p>
                              <blockquote/>
                                  <div className="blockquote-text"><span className="quote icofont-quote-left"></span>Our greatest weakness lies in giving up. <br/> The most certain way to succeed is always to <br/> try just one more time.</div>
                          
                              <p>Both of these assumptions, of course, could be entirely false. Self-censoring is firmly rooted in our experiences with mistakes in the past and not the present. The brain messages arising from those experiences can be deceptive. </p>
                              
                          
                              <div className="post-share-options">
                                  <div className="tags"><Link to="#">Structure</Link> <Link to="#">Envato</Link> <Link to="#">Premium</Link></div>
                              </div>
                              
                          </div>
                      </div>
                  </div>
                  
                  
                  <div className="related-projects">
                      <div className="title-box">
                          <h3>Related Posts</h3>
                      </div>
                      <div className="row clearfix">
                          
                      
                          <div className="news-block-four col-lg-6 col-md-6 col-sm-12">
                              <div className="inner-box">
                                  <div className="image">
                                      <Link to="blog-detail.html"><img src={Money1} alt="" /></Link>
                                  </div>
                                  <div className="lower-content">
                                      <div className="title">business</div>
                                      <h4><Link to="blog-detail.html">Problems About Social Insurance For Truck Drivers</Link></h4>
                                  </div>
                              </div>
                          </div>
                          
                          <div className="news-block-four col-lg-6 col-md-6 col-sm-12">
                              <div className="inner-box">
                                  <div className="image">
                                      <Link to="blog-detail.html"><img src="images/resource/news-5.jpg" alt="" /></Link>
                                  </div>
                                  <div className="lower-content">
                                      <div className="title">News</div>
                                      <h4><Link to="blog-detail.html">5 Steps To Build Strategy Planning</Link></h4>
                                  </div>
                              </div>
                          </div>
                          
                      </div>
                  </div>
                  
              </div>
              
          
              <div className="sidebar-side col-lg-3 col-md-12 col-sm-12">
                  <aside className="sidebar sticky-top margin-left">
                      
                      
                      <div className="sidebar-widget search-box">
                          <div className="sidebar-title">
                              <h4>Search</h4>
                          </div>
                          <form method="post" action="/contact">
                              <div className="form-group">
                                  <input type="search" name="search-field" value="" placeholder="Type & Hit Enter..." required/>
                                  <button type="submit"><span className="icon fa fa-search"></span></button>
                              </div>
                          </form>
                      </div>
                      
                      
                      <div className="sidebar-widget categories-blog">
                          <div className="sidebar-title">
                              <h4>Categories</h4>
                          </div>
                          <ul>
                              <li><Link to="#">All <span>25</span></Link></li>
                              <li><Link to="#">News <span>12</span></Link></li>
                              <li><Link to="#">business <span>7</span></Link></li>
                              <li><Link to="#">tips & tricks <span>4</span></Link></li>
                              <li><Link to="#">Others <span>2</span></Link></li>
                          </ul>
                      </div>
                      
                  
                      <div className="sidebar-widget popular-posts">
                          <div className="sidebar-title">
                              <h4>Recent Posts</h4>
                          </div>
                          <div className="widget-content">
                              <div className="post">
                                  <figure className="post-thumb"><Link to="blog-single.html"><img src="images/resource/post-thumb-1.jpg" alt=""/></Link></figure>
                                  <div className="text"><Link to="blog-single.html">5 Secrets To Coaching Your Employees To Greatness</Link></div>
                              </div>
                              
                              <div className="post">
                                  <figure className="post-thumb"><Link to="blog-single.html"><img src="images/resource/post-thumb-2.jpg" alt=""/></Link></figure>
                                  <div className="text"><Link to="blog-single.html">5 Steps To Build Strategy Planning</Link></div>
                              </div>
                              
                              <div className="post">
                                  <figure className="post-thumb"><Link to="blog-single.html"><img src="images/resource/post-thumb-3.jpg" alt=""/></Link></figure>
                                  <div className="text"><Link to="blog-single.html">Trend Of Consumer Market 2024</Link></div>
                              </div>
                          </div>
                      </div>
                      
                      
                      <div className="sidebar-widget tags">
                          <div className="sidebar-title">
                              <h4>Tags</h4>
                          </div>
                          <div className="widget-content">
                              <Link to="#">Structure</Link>
                              <Link to="#">Envato</Link>
                              <Link to="#">Premium</Link>
                              <Link to="#">Clean</Link>
                              <Link to="#">WordPress</Link>
                          </div>
                      </div>
                      
                  </aside>
              </div>
              
          </div>
      </div>
  
  
</div>

{/* <div className="container d-flex justify-content-center mt-5">
    <div className="comment-box w-75 p-4 bg-light shadow-lg rounded border border-secondary" style={{ maxWidth: '500px' }}>
        <h3 className="text-center text-3xl font-bold mb-4 text-dark">Leave a Comment</h3>
        <form className="comment-form d-flex flex-column" onSubmit={handleSubmit}>
            <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Your Name"
                className="form-control mb-3"
            />
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Your Email"
                className="form-control mb-3"
            />
            <textarea
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Your Comment"
                className="form-control mb-3"
            ></textarea>
            <button
                type="submit"
                className="btn btn-success btn-block"
            >
                POST COMMENT
            </button>
        </form>
        {comments.length > 0 && (
            <div className="mt-4">
                <h4 className="text-2xl font-bold mb-3 text-dark">Comments</h4>
                <ul className="list-unstyled">
                    {comments.map((c, index) => (
                        <li key={index} className="p-3 mb-3 bg-white border border-secondary rounded shadow-sm">
                            <p className="font-weight-bold text-dark">Name: {c.name}</p>
                            <p className="text-muted">Email: {c.email}</p>
                            <p className="mt-2 text-dark">Comment: {c.comment}</p>
                            <button
                                onClick={() => handleDelete(index)}
                                className="btn btn-danger mt-2"
                            >
                                DELETE
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        )}
    </div>
</div> */}
    <Footer />
    <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
  </div>
  
  </>
  );
};

export default BlogDetail;
