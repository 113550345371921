import React, { useEffect, useState } from "react";
import { useAddFormDataMutation,useGetFormDataQuery} from '../redux/api/form';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Services from '../../src/assets/invest.jpeg';
import { useNavigate } from 'react-router-dom';

function Index() {

  const navigate = useNavigate();
  const {
  data: FormDataResponse,
  isLoading: isincomesLoading,
  error: incomesError,
} = useGetFormDataQuery();
const [form, setForm] = useState([]);
const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (FormDataResponse) {
      setForm(FormDataResponse.data);
      setLoading(false); 
    }
  }, [FormDataResponse]);
  const [language, setLanguage] = useState("en");

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };
 

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telephone: '',
    gender: '',
    investment_key1: '',
    investment_key2: '', 
    investment_key3: '' 
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [addFormData] = useAddFormDataMutation();
  const isLargeScreen = window.innerWidth >= 1024; 

const leftMarginStyle = isLargeScreen ? { marginLeft: '10px' } : {};
  const handleNext = () => {
    const newErrors = {};

    if (step === 1) {
      const nameError = validateName(formData.name);
      if (nameError) {
        newErrors.name = nameError;
      }
  
      if (!formData.email) {
        newErrors.email = 'Email is required';
      } else if (!formData.email.includes('@')) {
        newErrors.email = 'Email must contain "@"';
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = 'Please enter a valid email address';
      }
  
     
      if (!formData.telephone) {
        newErrors.telephone = 'Phone Number is required';
      } else if (!/^\+([0-9]{1,4})\s?([0-9]{1,4})\s?([0-9]{1,4})(\s?[0-9]{1,4}){0,3}$/.test(formData.telephone)) {
        newErrors.telephone = 'Phone Number must be valid (e.g., +961 3 333 333 or +1 234 567 890)';
      }
      
      if (!formData.gender) {
        newErrors.gender = 'Gender is required';
      }
    }

  

    if (step === 2) {
      if (!formData.investment_key1) newErrors.investment_key1 = 'Please choose one option';
    }

    if (step === 3) {
      if (!formData.investment_key2) newErrors.investment_key2 = 'Please choose one option';
    }

    if (step === 4) {
      if (!formData.investment_key3) newErrors.investment_key3 = 'Please choose one option';
    }

    if (step === 5) {
      if (!formData.termsAccepted) newErrors.termsAccepted = 'You must accept the terms and conditions';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setStep((prevStep) => Math.min(prevStep + 1, 5));
    }
  };
  const handleKeyUp = (e) => {
    const newErrors = {};

    if (step === 1) {
      if (!formData.name) {
        newErrors.name = 'Name is required';
      } else if (formData.name.trim().length < 3) {
        newErrors.name = 'Name must be at least 3 characters long';
      }
  
      if (!formData.email) {
        newErrors.email = 'Email is required';
      } else if (!formData.email.includes('@')) {
        newErrors.email = 'Email must contain "@"';
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = 'Please enter a valid email address';
      }
  
     
      if (!formData.telephone) {
        newErrors.telephone = 'Phone Number is required';
      } else if (!/^\+([0-9]{1,4})\s?([0-9]{1,4})\s?([0-9]{1,4})(\s?[0-9]{1,4}){0,3}$/.test(formData.telephone)) {
        newErrors.telephone = 'Phone Number must be valid (e.g., +961 3 333 333 or +1 234 567 890)';
      }
      
      if (!formData.gender) {
        newErrors.gender = 'Gender is required';
      }
    }
    handleChange(e);
  
  };
  const handlePrev = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
};
  const [errorMessage, setErrorMessage] = useState(null); 
  const [isErrorVisible, setIsErrorVisible] = useState(false); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.termsAccepted) {
      newErrors.termsAccepted = 'You must accept the terms and conditions.';
    }
    setIsErrorVisible(false);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; 
    }
  
    console.log('Form Data:', formData);
    setSubmitted(true); 
  
    try {
      await addFormData(formData).unwrap();
      resetForm();
    } catch (error) {
      console.error('Failed to submit form:', error);
      if (error?.data?.error) {
        setErrorMessage(error.data.error[0]);
        setIsErrorVisible(true);
        resetForm();
      } else {
        setErrorMessage('An unknown error occurred. Please try again later.');
      }
    }
    
  };
  
  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      telephone: '',
      gender: '',
      investment_key1: '',
      termsAccepted: false,
      investment_key2: '',
      investment_key3: ''
    });
    setStep(1); 
  };
  const validateName = (name) => {
    if (!name) return 'Name is required';
    if (!/^[a-zA-Z\s]+$/.test(name)) return 'Name can only contain letters and spaces';
    return '';
  };
  
  return (
    <div 
  style={{ 
    width: '100%', 
    padding: '20px', 
    boxSizing: 'border-box', 
    backgroundImage: isLargeScreen?`url(${Services})`:'', 
    position: 'relative',
    backgroundSize: 'cover',     
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor:isLargeScreen?'':'#f4f4df'

  }}
>
       <div
    style={{
        padding: '30px',
        borderRadius: '8px',
        zIndex: 2,
       width:isLargeScreen?"50%":"",
       transform: isLargeScreen?"translateX(2%)":"",

    }}
>
          <div className="inner-column">
            <div className="form-boxed">
              <div className="consult-form">
                <form onSubmit={handleSubmit}>
                  {/* Step Indicator */}
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <div style={{ width: '20%', height: '5px', background: step > 1 ? '#13264C' : '#ddd' }}></div>
                    <div style={{ width: '20%', height: '5px', background: step > 2 ? '#13264C' : '#ddd' }}></div>
                    <div style={{ width: '20%', height: '5px', background: step > 3 ? '#13264C' : '#ddd' }}></div>
                    <div style={{ width: '20%', height: '5px', background: step > 4 ? '#13264C' : '#ddd' }}></div>
                    <div style={{ width: '20%', height: '5px', background: step >= 5 ? '#13264C' : '#ddd' }}></div>
                  </div>

                   {/* Step 1 */}
                   {step === 1 && (
  <div className="step">
    <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', textAlign: 'start' }}>Investment Form</h2>
    <h3 style={{ fontSize: '20px', color: '#666', textAlign: 'start', marginBottom: '20px' }}>Personal Info</h3>
    
    <div style={{ marginBottom: '20px' }}>
      <label htmlFor="name" style={{ fontWeight: '500', color: '#333' ,fontSize:"20px"}}>First and Last Name</label>
      <input
        type="text"
        name="name"
        id="name"
        className="form-control p-2"
        placeholder="Enter your Full Name"
        value={formData.name}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
       
        onFocus={(e) => e.target.style.border = '2px solid #13264C'}
        onBlur={(e) => e.target.style.border = errors.name ? '1px solid red' : '1px solid #ddd'}
      />
      {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
    </div>
    
    
    <div style={{ marginBottom: '20px' }}>
  <label htmlFor="email" style={{ fontWeight: '500', color: '#333'  ,fontSize:"20px"}}>Email Address</label>
  <input
    type="email"
    name="email"
    id="email"
    placeholder="Enter your Email Address"
     className="form-control p-2"
    value={formData.email}
    onChange={handleChange}
    onKeyUp={handleKeyUp}
    onFocus={(e) => e.target.style.border = '2px solid #13264C'}
    onBlur={(e) => e.target.style.border = errors.email ? '1px solid red' : '1px solid #ddd'}
  />
  {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
</div>

<div style={{ marginBottom: '20px' }}>
  <label htmlFor="telephone" style={{ fontWeight: '500', color: '#333'  ,fontSize:"20px"}}>Phone Number</label>

  <PhoneInput
    international
    name="telephone"
    id="telephone"
    defaultCountry="LB"
    value={formData.telephone}
    onChange={handlePhoneChange}
    style={{
      borderRadius: '4px',
      boxSizing: 'border-box',
      
      marginLeft: '-330px',
    }}
    onKeyUp={handleKeyUp}
    onFocus={(e) => e.target.style.border = '2px solid #13264C'}
    onBlur={(e) => e.target.style.border = errors.telephone ? '2px solid red' : '1px solid #ddd'}
  />
 {errors.telephone && <p style={{ color: 'red' ,marginLeft:"10px"}}>{errors.telephone}</p>}
</div>

    
    <label style={{ fontWeight: '500', color: '#333'  ,fontSize:"20px"}}>Gender {errors.gender && <p style={{ color: 'red' ,marginLeft:"10px"}}>{errors.gender}</p>}</label>
    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginBottom: '20px', justifyContent: 'start' }}>
      <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' ,fontSize:"18px" ,marginTop:"5px"}}>
       <span style={{marginRight:"5px"}}>Male</span> 
        <input
          type="radio"
          name="gender"
          value="Male"
          checked={formData.gender === 'Male'}
          onChange={handleChange}
          style={{ marginLeft: '5px', accentColor: '#13264C', transform: 'scale(1.5)' }}
        />
      </label>
      <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' ,fontSize:"18px",marginLeft:"10px" ,marginTop:"5px"}}>
      <span style={{marginRight:"5px"}}>Female</span> 
        <input
          type="radio"
          name="gender"
          value="Female"
          checked={formData.gender === 'Female'}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          style={{ marginLeft: '5px', accentColor: '#13264C' , transform: 'scale(1.5)'}}
        />
      </label>
    </div>
    
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <button type="button" onClick={handleNext} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: '#13264C', color: '#fff', border: 'none', cursor: 'pointer' }}>
        Next
      </button>
    </div>
  </div>                  
)}


                  {/* Step 2 */}
                  {step === 2 && (
  <div>
    <h2 style={{ fontSize: '28px', fontWeight: 'bold', color: '#333', textAlign: 'center', marginBottom: '20px' }}>Market Downturn Scenario</h2>
    <div style={{ marginBottom: '20px', fontSize: '16px', color: '#555', lineHeight: '1.6' }}>
      <p>Imagine the stock market has just experienced a significant downturn, with major indices dropping by 20% in a month. You have investments worth $50,000 in your portfolio.</p>
      <label style={{ display: 'block', fontWeight: '500', color: '#333', marginBottom: '10px' }}>How would you respond to this situation?</label>

      <div style={{ paddingLeft: '10px' }}>
        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key1_conservative"
            name="investment_key1"
            value="Conservative"
            checked={formData.investment_key1 === 'Conservative'}
            onChange={handleChange}
            style={{ marginRight: '10px', accentColor: '#13264C' }}
          />
          Sell a Portion of Investments
        </label>

        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key1_moderate"
            name="investment_key1"
            value="Moderate"
            checked={formData.investment_key1 === 'Moderate'}
            onChange={handleChange}
            style={{ marginRight: '10px', accentColor: '#13264C' }}
          />
          Hold Steady
        </label>

        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key1_growth"
            name="investment_key1"
            value="Growth"
            checked={formData.investment_key1 === 'Growth'}
            onChange={handleChange}
            style={{ marginRight: '10px', accentColor: '#13264C' }}
          />
          Buy More Investments
        </label>

        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key1_diversify"
            name="investment_key1"
            value="Diversify"
            checked={formData.investment_key1 === 'Diversify'}
            onChange={handleChange}
            style={{ marginRight: '10px', accentColor: '#13264C' }}
          />
          Diversify Your Portfolio
        </label>
        {errors.investment_key1 && <p style={{ color: 'red', marginTop: '10px', fontSize: '14px' }}>{errors.investment_key1}</p>}
      </div>
    </div>

    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
      <button
        type="button"
        onClick={handlePrev}
        style={{
          padding: '10px 20px',
          borderRadius: '5px',
          backgroundColor: '#888',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
      >
        Previous
      </button>
      <button
        type="button"
        onClick={handleNext}
        style={{
          padding: '10px 20px',
          borderRadius: '5px',
          backgroundColor: '#13264C',
          color: '#fff',
          border: 'none',
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
      >
        Next
      </button>
    </div>
  </div>
)}

{step === 3 && (
  <div>
    <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', textAlign: 'center' }}>Investment Opportunity Evaluation</h2>
    <div style={{ marginBottom: '20px', fontSize: '16px', color: '#555', lineHeight: '1.6' }}>
      <h6>A new high-growth tech startup is offering shares at an initial public offering (IPO). The company has innovative products but operates in a highly competitive market with no guaranteed success.</h6>
      <label style={{ fontWeight: '500', color: '#333' }}>Would you invest in this IPO?</label>
      <div style={{ marginTop: '10px' }}>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key2_conservative"
            name="investment_key2"
            value="Conservative"
            checked={formData.investment_key2 === 'Conservative'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#13264C' }}
          />
          Invest a Small Percentage
        </label>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key2_moderate"
            name="investment_key2"
            value="Moderate"
            checked={formData.investment_key2 === 'Moderate'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#13264C' }}
          />
          Invest a Moderate Percentage
        </label>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key2_growth"
            name="investment_key2"
            value="Growth"
            checked={formData.investment_key2 === 'Growth'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#13264C' }}
          />
          Invest a Large Percentage
        </label>
        <label style={{ display: 'block', cursor: 'pointer', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key2_avoid"
            name="investment_key2"
            value="Avoid"
            checked={formData.investment_key2 === 'Avoid'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#13264C' }}
          />
          Avoid Investing
        </label>
        {errors.investment_key2 && <p style={{ color: 'red', marginTop: '10px', fontSize: '14px' }}>{errors.investment_key2}</p>}
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <button type="button" onClick={handlePrev} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: 'gray', color: '#fff', border: 'none', cursor: 'pointer', fontWeight: 'bold' }}>
        Previous
      </button>
      <button type="button" onClick={handleNext} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: '#13264C', color: '#fff', border: 'none', cursor: 'pointer', fontWeight: 'bold' }}>
        Next
      </button>
    </div>
  </div>
)}

{step === 4 && (
  <div>
    <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', textAlign: 'center' }}>Long-Term Financial Goals</h2>
    <div style={{ marginBottom: '20px', fontSize: '16px', color: '#555', lineHeight: '1.6' }}>
      <h6>You have set a long-term financial goal to retire comfortably in 20 years. Currently, your investments are a mix of bonds and stocks.</h6>
      <label style={{ fontWeight: '500', color: '#333' }}>How would you adjust your investment strategy to achieve this goal?</label>
      <div style={{ marginTop: '10px' }}>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key3_conservative"
            name="investment_key3"
            value="Conservative"
            checked={formData.investment_key3 === 'Conservative'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#13264C' }}
          />
          Maintain Current Strategy
        </label>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key3_growth"
            name="investment_key3"
            value="Growth"
            checked={formData.investment_key3 === 'Growth'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#13264C' }}
          />
          Shift Towards Growth Investments
        </label>
        <label style={{ display: 'block', cursor: 'pointer', marginBottom: '8px', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key3_moderate"
            name="investment_key3"
            value="Moderate"
            checked={formData.investment_key3 === 'Moderate'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#13264C' }}
          />
          Adopt a Balanced Strategy
        </label>
        <label style={{ display: 'block', cursor: 'pointer', fontSize: '15px', color: '#444' }}>
          <input
            type="radio"
            id="investment_key3_avoid"
            name="investment_key3"
            value="Avoid"
            checked={formData.investment_key3 === 'Avoid'}
            onChange={handleChange}
            style={{ marginRight: '5px', accentColor: '#13264C' }}
          />
          Avoid Riskier Investments
        </label>
        {errors.investment_key3 && <p style={{ color: 'red', marginTop: '10px', fontSize: '14px' }}>{errors.investment_key3}</p>}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between',marginTop:"20px" }}>
      <button type="button" onClick={handlePrev} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: 'gray', color: '#fff', border: 'none', cursor: 'pointer', fontWeight: 'bold' }}>
        Previous
      </button>
      <button type="button" onClick={handleNext} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: '#13264C', color: '#fff', border: 'none', cursor: 'pointer', fontWeight: 'bold' }}>
        Next
      </button>
    </div>
    </div>
  </div>
)}



                  {/* Step 5 - Terms and Conditions */}
                  {step === 5 && (
                    <div className="step">
                      <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', textAlign: 'center' }}>Terms and Conditions</h2>
                      <div style={{ marginBottom: '20px' }}>
                        <label style={{ fontWeight: '500', color: '#333' }}>
                          <input
                            type="checkbox"
                            name="termsAccepted"
                            checked={formData.termsAccepted}
                            onChange={handleChange}
                            style={{ marginRight: '5px' }}
                          />
                          I accept the terms and conditions
                        </label>
                        {errors.termsAccepted && <p style={{ color: 'red' }}>{errors.termsAccepted}</p>}
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button type="button" onClick={handlePrev} style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: 'gray', color: '#fff', border: 'none', cursor: 'pointer' }}>
                          Previous
                        </button>
                        <button type="submit" style={{ padding: '10px 15px', borderRadius: '5px', backgroundColor: '#13264C', color: '#fff', border: 'none', cursor: 'pointer' }}>
                          Submit
                        </button>

                        
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
      
      </div>
      {isErrorVisible && errorMessage && (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // Fixes the error message in plac
    zIndex: 9999,       // Ensure the error message is above other content
  }}>
    <div style={{
      color: '#fff',
      backgroundColor: '#ff4d4f', // Red background for error
      padding: '20px',
      borderRadius: '10px',
      textAlign: 'center',
      animation: 'fadeIn 0.5s ease',
      maxWidth: '400px',
      width: '100%',
    }}>
      <h4 style={{
        fontFamily: 'Arial, sans-serif',
        margin: 0,
      }}>
        {errorMessage}
      </h4>
    </div>
  </div>
)}



{!isErrorVisible && submitted && form.length > 0 && (
  <div style={{
    textAlign: 'center',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#fff', 
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' 
  }}>
    <h3 style={{
      fontFamily: 'Arial, sans-serif',
      color: '#333',
      margin: '20px 0'
    }}>
      <span style={{
        fontWeight: 'bold',
        color: '#007bff' 
      }}>
        {form[form.length - 1].name}
      </span> You are a: {form[form.length - 1].type} Person. See what service you need.
    </h3>
    <button onClick={() => {
      const type = form[form.length - 1].type;
     
      
      navigate('/service/wealth', {
        state: {
          name: form[form.length - 1].name,
          type: form[form.length - 1].type,
        }
      });
    }} style={{
      marginTop: '15px',
      padding: '10px 20px',
      fontSize: '1rem',
      color: '#fff',
      backgroundColor: '#28a745', 
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s'
    }}>
      See Service
    </button>
  </div>
)}








    
    </div>
  );
}

export default Index;
