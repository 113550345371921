import React,{useState,useRef,useEffect} from 'react';
import Footer from '../components/footer'
import Service from '../../src/assets/service1.png';
import Service2 from '../../src/assets/service2.png';
import Service3 from '../../src/assets/service3.png';
import Service4 from '../../src/assets/service4.png';
import S1 from '../assets/portfolio.png';
import Header from '../components/header'
import { Link } from 'react-router-dom';
import S1Img from '../../src/assets/s1img.png';
import S1Img1 from '../../src/assets/s1img1.png';
import { useLocation } from 'react-router-dom';
import InvestmentPlanType from '../components/Discover_Investment_Plan';
import { FaArrowDown } from 'react-icons/fa';
import {Carousel} from 'react-bootstrap';

function Wealth (){
	const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);
	const location = useLocation();
	const { name, type } = location.state || { name: '', type: '' }; 
	const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  const steps = [
    {
        icon: "fas fa-briefcase",
        title: "Investment Consultancy and Assessment",
        description: "Our relationship with clients begins with an in-depth consultancy session where we assess their financial situation, goals, and risk tolerance. This phase is crucial for creating an investment approach aligned with each client’s vision.",
    },
    {
        icon: "fas fa-chart-line",
        title: "Broker Selection and Fund Deposit",
        description: "We provide clients with a choice of our partnered, reputable brokerages where they can securely deposit their funds. While Beta Investment Group is not a brokerage, our partners help facilitate investments with trusted security measures, ensuring clients’ peace of mind.",
    },
    {
        icon: "fas fa-rocket",
        title: "Portfolio Growth and Management",
        description: "Once funds are deposited with the chosen broker, our team initiates the active investment strategy, continuously monitoring and adjusting based on market conditions. We take an agile approach, allowing clients to benefit from proactive management designed to maximize returns and minimize risks.",
    },
];
	useEffect(() => {
	  const handleResize = () => {
		setIsLargeScreen(window.innerWidth >= 1024);
	  };
	  window.addEventListener('resize', handleResize);
	  return () => {
		window.removeEventListener('resize', handleResize);
	  };
	}, []);

  return (
    <div className="page-wrapper">
     <Header/>

	
    <div className="sidebar-page-container">
    	<div className="auto-container">
			<br/>
			{name && type ? (
      <>
        <h1 style={{ fontWeight: 'normal', color: 'black' }} className='text-success'>Welcome, {name}!</h1>
        <p style={{ fontSize: '30px', color: 'black' }}>As a {type} person, here are the features of your type: <span 
  className="bg-success p-1 rounded-[15px] cursor-pointer" 
  onClick={() => {
    const formElement = document.getElementById("invest");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }}
 style={{borderRadius:"50px" ,color:"white"}}>
  <FaArrowDown size={25} />
</span></p>
      </>
    ) :  <h1 style={{ color: 'black',fontWeight:"inherit" }}></h1>}
			<div className="service-title-box">
			<br/>
				<h2 style={{ color: 'black',fontWeight:"normal" ,fontWeight:"bold"}}>Wealth management</h2>
			</div>
        	<div className="row clearfix">
            	
           
                <div className="content-side col-lg-9 col-md-12 col-sm-12 animate__animated animate__fadeInUp">
					<div className="service-detail">
						<div className="inner-box">
							<div className="image">
								<img src={Service} alt="" />
							</div>
							<div className="lower-conten animate__animated animate__fadeInUpt">
                
								<h4 className='text-black'>Empowering Your Financial Journey with a Tailored Approach </h4>
								<p>At Beta Investment Group, we understand that wealth management is about more than numbers; it's about achieving your life goals and creating a legacy. Our Wealth Management service is designed to help you maximize your financial potential through a carefully constructed, personalized approach. We start by working closely with you to understand your financial goals, current situation, and risk tolerance. Using this information, our advisors craft an individualized investment strategy aimed at wealth growth, preservation, and risk mitigation.                </p>
               
                <div
  className="container-fluid animate__animated animate__fadeInUp"
  style={{
    color: '#1D3557',
    marginBottom: '50px',
    marginTop: '-10px',
  }}
>
  <div className="row ">
    <div className="col-12">
      <h1
        className="font-weight-bold"
        style={{ fontSize: '2rem', color: '#1D3557' }}
      >
        Our Investment Process
      </h1>
    </div>
    <div className="col-md-8">
      <div
        className="d-flex flex-column align-items-start"
        style={{ gap: '1rem' }}
      >
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step-box animate__animated animate__fadeInUp animate__delay-${index + 1}s`}
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              textAlign: 'left',
              padding: '1rem',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#FFFFFF',
              borderLeft: '4px solid #2A9D8F',
              opacity: 1,
              transition: 'opacity 0.5s ease',
              marginLeft: isLargeScreen ? `${index * 120}px` : '0',
              marginTop: '10px',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <div
              style={{
                width: '30px',
                height: '30px',
                backgroundColor: '#2A9D8F',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '1rem',
                color: '#FFFFFF',
                fontSize: '1rem',
              }}
            >
              <i className={step.icon}></i>
            </div>
            <div style={{ flex: 1 }}>
              <h2
                style={{
                  color: '#1D3557',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                }}
              >
                {step.title}
              </h2>
              <p
                style={{
                  color: '#457B9D',
                  fontSize: '0.8rem',
                  lineHeight: '1.6',
                  margin: 0,
                }}
              >
                {step.description}
              </p>
            </div>
          </div>
        ))}
         <div id="invest"></div>
      </div>
    </div>
  </div>
</div>

								
				<InvestmentPlanType selectedPlan={type}/>		
								
							
								
								
								
							</div>
						</div>
					</div>
			
				<div
        className="image-container animate__animated animate__fadeInUp animate__delay-5s"
        style={{
          position: 'relative',
          width: '100%',
          maxHeight: '400px'
        }}
      >
        <img
          src={S1}
          alt="Background"
          style={{
            width: '100%',
            maxHeight: '400px',
            height: 'auto',
            objectFit: 'cover',
          }}
        />
        <div
          className="overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            zIndex: 1,
          }}
        />
        <div
          className="text animate__animated animate__fadeInRight animate__delay-6s"
          style={{
            position: 'absolute',
			      top: isLargeScreen?'40%':'20%',
            left: isLargeScreen?'40%':'5%',
            transform: isLargeScreen?'translate(-10%, -380%)':'translate(-50%, -200%)',
            color: 'white',
            fontSize: '30px',
            fontWeight: 'bold',
            zIndex: 2,
          }}
        >
         Wealth Management
        </div>


		<div
          className="text animate__animated animate__fadeInRight animate__delay-6s"
          style={{
            position: 'absolute',
            top: isLargeScreen?'50%':'40%',
            left: isLargeScreen?'40%':'5%',
            transform: isLargeScreen?'translate(-5%, -30%)':'translate(-50%, -15%)',
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
            zIndex: 2,
          }}
        >
         Our Wealth Management service gives you peace of mind by focusing on sustainable growth while safeguarding against risks, helping you achieve financial independence and stability.
        
		</div>
    {isLargeScreen?
		<div className="col-md-5 col-sm-12 text-center "  style={{
            position: 'relative',
            zIndex: 10,
            transform:isLargeScreen?'translateY(-300px)':'translateY(20px)', 
          }}>
      <img 
        src={S1Img} 
        alt="Contact Form Illustration" 
        style={{ maxWidth: isLargeScreen?'100%':'80%', height: 'auto' }} 
      />
    </div>
    :null}
      </div>
	

				</div>
             
					
        {isLargeScreen ? (
        <div className="sidebar-side col-lg-3 animate__animated animate__fadeInUp animate__delay-1s">
          <aside className="sidebar sticky-top">
            <div className="sidebar-widget categories-widget">
              <h4 className="text-black">View Other Services</h4>
              <div className="category-boxed mb-4">
                <Link to="/service/investment" className="relative block rounded-lg overflow-hidden">
                  <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                    <img
                      src={Service2}
                      alt="Investment Advisory"
                      className="w-24 h-24 object-cover rounded-lg mb-4"
                    />
                    <h6 className="text-lg font-semibold text-gray-800 text-center">
                      Investment Advisory
                    </h6>
                  </div>
                </Link>
              </div>
              <div className="category-boxed mb-4">
                <Link to="/service/financial" className="relative block rounded-lg overflow-hidden">
                  <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                    <img
                      src={Service3}
                      alt="Wealth Planning"
                      className="w-24 h-24 object-cover rounded-lg mb-4"
                    />
                    <h6 className="text-lg font-semibold text-gray-800 text-center">
                      Financial Planning
                    </h6>
                  </div>
                </Link>
              </div>
              <div className="category-boxed mb-4">
                <Link to="/service/corporate" className="relative block rounded-lg overflow-hidden">
                  <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                    <img
                      src={Service4}
                      alt="Corporate Financial Management"
                      className="w-24 h-24 object-cover rounded-lg mb-4"
                    />
                    <h6 className="text-lg font-semibold text-gray-800 text-center">
                      Corporate Financial Management
                    </h6>
                  </div>
                </Link>
              </div>
            </div>
          </aside>
        </div>
      ) : (
        <Carousel interval={5000} >
          {[Service2, Service3, Service4].map((service, idx) => (
            <Carousel.Item key={idx}>
              <div className="category-boxed mb-4">
                <Link to={`/service/${service === Service2 ? 'investment' : service === Service3 ? 'financial' : 'corporate'}`} className="relative block rounded-lg overflow-hidden">
                  <div className="boxed-inner flex flex-col justify-center items-center p-1 bg-gray-100 rounded-lg transition-shadow duration-300 hover:shadow-lg">
                    <img
                      src={service}
                      alt={service === Service2 ? 'Investment Advisory' : service === Service3 ? 'Wealth Planning' : 'Corporate Financial Management'}
                      className="w-24 h-24 object-cover rounded-lg mb-4"
                    />
                    <h6 className="text-lg font-semibold text-gray-800 text-center">
                      {service === Service2 ? 'Investment Advisory' : service === Service3 ? 'Financial Planning' : 'Corporate Financial Management'}
                    </h6>
                  </div>
                </Link>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      )}


			</div>
		</div>
	</div>
      <Footer />
	  <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
    </div>
  );
};

export default Wealth;
