import { apiSlice } from './apiSlice';

export const OnBoardingFormApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
   
    addOnBoardingFormData: builder.mutation({
      query: (Data) => ({
        url: `/onboarding`,
        method: 'POST',
        body: Data,
    }),
    invalidatesTags: [{ type: 'OnBoardingFormData' }],
    }),
  }),
});


export const { useAddOnBoardingFormDataMutation } = OnBoardingFormApi;
