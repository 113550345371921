import React, { useState,useRef ,useEffect} from 'react';
import Footer from '../../src/components/footer'
import Services from '../../src/assets/services.png';
import Servicee1 from '../../src/assets/servicee1.webp';
import { useNavigate } from "react-router-dom"; 
import Servicee2 from '../../src/assets/servicee2.webp';
import Servicee3 from '../../src/assets/servicee3.webp';
import Servicee4 from '../../src/assets/servicee4.webp';
import Service1 from '../../src/assets/service1.png';
import Service2 from '../../src/assets/service2.png';
import Service3 from '../../src/assets/service3.png';
import Service4 from '../../src/assets/service4.png';
import InvestmentProcess from '../components/InvestementProcess';
import TestimonialSection from '../components/Testimonial';
import Header from '../../src/components/header'
import Index from '../components/index';
import WorldMap from '../components/world_map';

function Service (){
    const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);
    const isLargeScreen = window.innerWidth >= 1024; 
    const contentData = [
        { title: "Wealth Management", text: "Tailored Investing to your financial goals and risk appetite.", image: Service1 },
        { title: "Investment Advisory", text: "Personalized Investment Solutions designed to meet the unique needs of each client.", image: Service2 },
    
        { title: "Financial Planning", text: "Create a long-term wealth management plan encompassing your investment strategy, tax planning, retirement goals, and more.", image: Service3 },
        { title: "Corporate Financial Management", text: "Specialize in understanding the local market and economic challenges, offering investment options that align with Lebanon specific financial landscape.", image: Service4 },
  ];
  const images=[Servicee1,Servicee2,Servicee3,Servicee4]

  const navigate = useNavigate();

  const handleClick = (index) => {
    if (index === 0) {
      navigate("/service/wealth");
    } else if (index === 1) {
      navigate("/service/investment");
    }else if (index === 2) {
        navigate("/service/financial");
      }else if (index === 3) {
        navigate("/service/corporate");
      } else {
    }
  };


    const [activeIndex, setActiveIndex] = useState(0);
     

  return (
    <div className="page-wrapper">
    <Header/>

        <div className="image-container" style={{ position: 'relative', width: '100%', maxHeight: '400px' }}>
      <img 
        src={Services} 
        alt="Background" 
        style={{ 
          width: '100%', 
          maxHeight: '400px', 
          height: 'auto', 
          objectFit: 'cover' 
        }} 
      />
      <div 
        className="overlay" 
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.7)', 
          zIndex: 1
        }}
      />
      <div 
        className="text" 
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          fontSize: '36px',
          fontWeight: 'bold',
          zIndex: 2
        }}
      >
        Solutions 
      </div>
    </div>
    <br/>
    <div className="container">
      <div className="row">
      
        <div
          className="col-sm-6 d-flex align-items-center justify-content-center"
        >
          <img
            src={images[activeIndex]}
            alt={contentData[activeIndex].title}
            style={{
              maxWidth: "90%",
              height: "auto",
            }}
          />
        </div>

        {/* Right Content Section */}
        <div className="col-sm-6" style={{ padding: "20px 30px" }}>
          {contentData.map((content, index) => (
            <div
              key={index}
              className={`d-flex align-items-center ${
                activeIndex === index ? "shadow border-success" : "shadow-sm"
              }`}
              style={{
                marginBottom: "20px",
                padding: "15px",
                borderRadius: "8px",
                backgroundColor: activeIndex === index ? "#f3faf5" : "#fff",
                cursor: "pointer",
                borderWidth: "2px",
                borderStyle: activeIndex === index ? "solid" : "none",
                transition: "all 0.3s ease-in-out",
              }}
              onMouseEnter={() => setActiveIndex(index)} 
              onClick={() => handleClick(index)} >
              {/* Step Number */}
              <div className="column">
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "30px",
                    height: "30px",
                    backgroundColor: activeIndex === index ? "#28a745" : "#ddd",
                    color: "#fff",
                    fontWeight: "700",
                    borderRadius: "50%",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  {`0${index + 1}`}
                  
                </span>
                <h6
                    className={`mb-1 ${
                      activeIndex === index ? "text-success fw-bold" : "text-dark fw-normal"
                    }`}
                    style={{
                      fontSize: isLargeScreen? "18px":"15px",
                      marginBottom: "5px",
                    }}
                  >
                    {content.title}
                  </h6>
              </div>

              {/* Title, Text, and Image Section */}
              <div className="flex-grow-1 d-flex align-items-center justify-content-between">
                <div>
                  
                  <p
                    className="text-muted mb-0"
                    style={{
                      fontSize: isLargeScreen? "15px":"10px",
                      lineHeight: "1.5",
                      marginLeft:"10px"
                    }}
                  >
                    {content.text}
                  </p>
                </div>

                <img
                  src={content.image}
                  alt={content.title}
                  className="img-fluid"
                  style={{
                    maxWidth: "100px",
                    height: "auto",
                    borderRadius: "6px",
                    marginLeft:"10px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>


  <br/>
      <br/>

   <InvestmentProcess/>

  <br/>
  <WorldMap/>

  <TestimonialSection/>
  <br/>
  <Index/>

      <Footer />
      <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
    </div>
  );
};

export default Service;
